import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from 'globals/constants';
import request from 'utils/request';
import settingsHelpers from 'utils/settingsHelpers';

const { updateTimersInSettings } = settingsHelpers;

const useCreateTestSettings = () => {
  const mutation = useMutation(async (testSettings) => {
    const payload = updateTimersInSettings(testSettings, {
      from: 'minutes',
      to: 'seconds'
    });

    const response = await request({
      method: 'POST',
      endpoint: api.endpoints.test.create(),
      payload
    });

    return response;
  });

  const { mutateAsync, data, error, status } = mutation;
  return { createTest: mutateAsync, data, error, status };
};

const useUpdateTestSettings = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (dataSent) => {
      const { testId, settings } = dataSent;

      const payload = updateTimersInSettings(settings, {
        from: 'minutes',
        to: 'seconds'
      });

      const response = await request({
        method: 'PATCH',
        endpoint: api.endpoints.test.update(testId),
        payload
      });
      return response;
    },
    {
      onSettled: async (updatedData, err, variables) => {
        await queryClient.refetchQueries();
        queryClient.invalidateQueries([
          'testSettings',
          {
            testId: variables.testId
          }
        ]);

        const { response } = updatedData;
        const { data } = response;

        queryClient.setQueryData(
          [
            'testSettings',
            {
              testId: variables.testId
            }
          ],
          () => ({
            data
          })
        );

        //
        // // Convert seconds to minutes again
        // const updatedData = updateTimersInSettings(data, {
        //   from: 'seconds',
        //   to: 'minutes'
        // });
        //
        //
        //
        // queryClient.setQueryData(
        //   [
        //     'testSettings',
        //     {
        //       testId: variables.testId
        //     }
        //   ],
        //   (oldData) => ({
        //     ...oldData,
        //     data: { ...updatedData }
        //   })
        // );
      }
    }
  );

  const { mutateAsync, data, error, status } = mutation;

  return { updateTest: mutateAsync, data, error, status };
};

const useGetTestSettings = (testId) => {
  const { data, status, error } = useQuery(
    [
      'testSettings',
      {
        testId
      }
    ],
    async () => {
      if (!testId) return null;
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.test.findOne(testId)
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  return { data, status, error };
};

const useCloneTestSettings = () => {
  const mutation = useMutation(async ({ testId }) => {
    const response = await request({
      method: 'POST',
      endpoint: api.endpoints.test.clone(testId)
    });
    return response;
  });

  const { mutateAsync, data, error, isSuccess } = mutation;

  return { cloneTest: mutateAsync, data, error, isSuccess };
};

export { useUpdateTestSettings, useCreateTestSettings, useGetTestSettings, useCloneTestSettings };
