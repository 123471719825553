/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc, mixins } from 'styles/utilities';

// import { HAMBURGER_OPEN_CLASS } from './variables';

const layoutChangeBreakpoint = mixins.respondTo(misc.layoutChangeBreakpoint());

export const header = css`
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${misc.navZindex};
  background-color: ${colors.black};
  ${mixins.transition({ properties: 'transform' })}
  &.nav-up {
    transform: translateY(-100%);
  }
  &.nav-down {
    transform: none;
  }
`;

export const navBand = css`
  .nav-band-left {
    width: 100%;
  }
`;

// Logo styling
export const logoLink = css`
  display: flex;
  line-height: 1;
  align-items: center;
  .logo-text {
    margin-left: 20px;
    white-space: nowrap;
  }
`;

export const logo = css`
  width: 24px;
  height: 24px;
  ${layoutChangeBreakpoint} {
    width: 40px;
    height: 40px;
  }
`;

export const navCoinButton = css`
  background-color: ${mixins.toRgba(colors.white, 0.2)};
  color: ${colors.white};
  padding-top: 8px;
  padding-bottom: 8px;
  ${mixins.linkActiveStyles()} {
    color: ${colors.white};
  }
`;
