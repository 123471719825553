import { useState, useCallback, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { findIndex, cloneDeep, omit, find } from 'lodash-es';

import { useCreateTestSettings, useUpdateTestSettings } from 'api/test';
import { TEST_STRUCTURE, EXTRA_TIME_TYPES } from 'globals/constants';
import settingsHelpers from 'utils/settingsHelpers';

const { formattedForAPI, updateTimersInSettings } = settingsHelpers;

export const initialSettings = {
  name: '',
  questionStructure: TEST_STRUCTURE.sections,
  resultsDisplayedAt: '',
  settings: {
    // Test settings
    enableLocking: false,
    showReportToCandidate: false,

    // General settings
    allowSkipQuestions: true,
    allowAnswerEditing: true,
    allowBackAndForthNavigation: false,

    // Timer settings
    enableTimer: true,
    timeLimit: 600, // in seconds
    enablePausingTimer: false,
    allowedExtraTime: 0,
    allowedExtraTimeType: EXTRA_TIME_TYPES.mins
  },
  markingSettings: {
    markingEnabled: true,
    defaultMarks: 1,
    negativeMarkingEnabled: false,
    defaultNegativeMarks: 0
  },
  sections: [],
  metadata: {
    description: ''
  }
};

const useCreateTest = (initialstate = initialSettings) => {
  const [testSettings, setTestSettings] = useState(initialstate);

  useEffect(() => {
    setTestSettings(initialstate);
  }, [initialstate]);

  const { createTest } = useCreateTestSettings();
  const { updateTest } = useUpdateTestSettings();

  // Update timers in settings
  useEffect(() => {
    setTestSettings((prevState) => ({
      ...updateTimersInSettings(prevState, {
        from: 'seconds',
        to: 'minutes'
      })
    }));
  }, [initialstate]);

  // Create a new test - All good
  const createNewTest = useCallback(
    async (settings) => {
      const { response } = await createTest(settings);
      const { data } = response;
      return data;
    },
    [createTest]
  );

  // Edit a test - All good
  const updateCurrentTest = useCallback(
    async (testId, changedSettings) => {
      const dataSent = { testId, settings: changedSettings };
      const { response } = await updateTest(dataSent);
      const { data } = response;
      return data;
    },
    [updateTest]
  );

  // add a section - Use `addNewSection_` instead
  const addNewSection = useCallback(
    async (dataObject, settings) => {
      const newData = dataObject;
      const tempArray = [...settings.sections, newData];
      const dataSent = {
        testId: settings._id,
        settings: { sections: tempArray }
      };
      const { response } = await updateTest(dataSent);
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [updateTest]
  );

  // edit a section - Use `updateSection_` instead
  const editCurrentSection = useCallback(
    async (dataObject, settings, arrayIndex) => {
      const tempArray = cloneDeep(settings.sections);
      if (!tempArray[arrayIndex].subsections) tempArray[arrayIndex].subsections = [];
      const tempSubArrays = [...tempArray[arrayIndex].subsections];
      tempArray[arrayIndex] = dataObject;
      tempArray[arrayIndex].subsections = tempSubArrays;
      const dataSent = { testId: settings._id, settings: { sections: tempArray } };
      const { response } = await updateTest(dataSent);
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [updateTest]
  );

  // add a subsection - Use `addNewSubSection_`
  const addNewSubsection = useCallback(
    async (dataObject, settings, arrayIndex) => {
      const tempArray = cloneDeep(settings.sections);
      if (!tempArray[arrayIndex].subsections) tempArray[arrayIndex].subsections = [];
      const tempSubArray = [...tempArray[arrayIndex].subsections, dataObject];
      tempArray[arrayIndex].subsections = tempSubArray;
      const dataSent = {
        testId: settings._id,
        settings: { sections: tempArray }
      };
      const { response } = await updateTest(dataSent);
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [updateTest]
  );

  // edit a subsection
  const editCurrentSubsection = useCallback(
    async (dataObject, settings, sectionIndex, arrayIndex) => {
      const tempArray = cloneDeep(settings.sections);
      if (!tempArray[sectionIndex].subsections) tempArray[sectionIndex].subsections = [];
      const tempSubArray = cloneDeep(tempArray[sectionIndex].subsections);
      tempSubArray[arrayIndex] = dataObject;
      tempArray[sectionIndex].subsections = tempSubArray;
      const dataSent = { testId: settings._id, settings: { sections: tempArray } };
      const { response } = await updateTest(dataSent);
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [updateTest]
  );

  // Update order of sections -- All good
  // Using this function for sub-sections as well.
  const updateSectionsOrder = useCallback(
    async (settings, updatedSectionsOrder) => {
      const dataSent = { testId: settings._id, settings: { sections: updatedSectionsOrder } };
      const { response } = await updateTest(dataSent);
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [updateTest]
  );

  // Fetch section data
  const fetchSectionData = useCallback((settings, sectionId) => {
    const sectionsArray = settings.sections;
    const sectionItem = sectionsArray.find((item) => item._id === sectionId);
    return sectionItem;
  }, []);

  // Fetch subsection data
  const fetchSubsectionData = useCallback((section, subSectionId) => {
    const subsectionArray = section.subsections;
    const subSectionItem = subsectionArray.find((item) => item._id === subSectionId);
    return subSectionItem;
  }, []);

  /// /////
  // New functions - Added by Viraj
  /// /////
  const addNewSection_ = useCallback(
    async (newSectionObject) => {
      const { response } = await updateTest({
        testId: testSettings._id,
        settings: {
          sections: [...testSettings.sections, newSectionObject]
        }
      });
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [testSettings._id, testSettings.sections, updateTest]
  );

  const updateSection_ = useCallback(
    async (existingSectionObject) => {
      // Ensures the previous data is not mutated;
      const updatedTestSettings = { ...testSettings };

      const settingObjectIndex = findIndex(updatedTestSettings.sections, {
        _id: existingSectionObject._id
      });
      const { sections } = updatedTestSettings;

      sections[settingObjectIndex] = {
        ...sections[settingObjectIndex],
        ...existingSectionObject
      };
      // sections.splice(settingObjectIndex, 1, existingSectionObject); // Old approach

      const { response } = await updateTest({
        testId: updatedTestSettings._id,
        settings: {
          sections
        }
      });
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [testSettings, updateTest]
  );

  const deleteSection = useCallback(
    async (existingSectionObjectID) => {
      const settingObjectIndex = findIndex(testSettings.sections, {
        _id: existingSectionObjectID
      });
      const { sections } = testSettings;
      sections.splice(settingObjectIndex, 1);
      const { response } = await updateTest({
        testId: testSettings._id,
        settings: {
          sections
        }
      });
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [testSettings, updateTest]
  );

  // manage form data before creating/editing test
  const normalizeTestSettings = useCallback((values, settings = null) => {
    // let negativeMarks = values.defaultNegativeMarks;
    // if (values.negativeMarkingEnabled === false) {
    //   negativeMarks = 0;
    // }

    // let pausingTimer = values.enablePausingTimer;
    // if (values.enableTimer === false) {
    //   pausingTimer = false;
    // }

    // let { enableLocking } = values;
    // if (values.questionStructure === TEST_STRUCTURE.test) {
    //   enableLocking = false;
    // }

    // let { defaultMarks } = values;
    // if (values.defaultMarksEnabled === false) {
    //   defaultMarks = 0;
    // }

    // let data = {
    //   name: values.name,
    //   questionStructure: values.questionStructure,
    //   resultsDisplayedAt: values.resultsDisplayedAt,
    //   settings: {
    //     allowSkipQuestions: values.allowSkipQuestions,
    //     allowAnswerEditing: values.allowAnswerEditing,
    //     allowBackAndForthNavigation: values.allowBackAndForthNavigation,
    //     enableLocking,
    //     enableTimer: values.enableTimer,
    //     enablePausingTimer: pausingTimer
    //   },
    //   markingSettings: {
    //     markingEnabled: values.defaultMarksEnabled,
    //     defaultMarks,
    //     negativeMarkingEnabled: values.negativeMarkingEnabled,
    //     defaultNegativeMarks: negativeMarks
    //   },
    //   metadata: {
    //     description: values.description
    //   }
    // };

    // if (values.questionStructure === TEST_STRUCTURE.test) {
    //   if (values.enableTimer) {
    //     data = { ...data, timeLimit: values.timeLimit };
    //   } else {
    //     data = { ...data, timeLimit: 0 };
    //   }
    // }

    let data = formattedForAPI(values);

    data = {
      ...data,
      meta: {
        ...data.meta,
        ...(values.description && { description: values.description }),
        ...(values.color && { color: values.color })
      }
    };

    if (values.questionStructure !== TEST_STRUCTURE.test) {
      if (settings) {
        const updatedData = omit(data, 'sections');
        return updatedData;
      }
      data = { ...data, sections: [] };
    }

    return data;
  }, []);

  // Add a new sub section
  const addNewSubSection_ = useCallback(
    async (newSubSectionObject, sectionId) => {
      // Ensures the previous data is not mutated;
      const updatedTestSettings = { ...testSettings };

      // Get parent section index
      const settingObjectIndex = findIndex(updatedTestSettings.sections, {
        _id: sectionId
      });
      const { sections } = updatedTestSettings;

      // Get parent section and update the subsection in it
      const parentSection = find(sections, { _id: sectionId });

      // Check if subsections array exists
      if (!parentSection.subsections) {
        parentSection.subsections = [];
      }
      parentSection.subsections = [
        ...(parentSection.subsections && parentSection.subsections),
        newSubSectionObject
      ];

      // Replace the parent section with updated section w/new sub-section
      sections.splice(settingObjectIndex, 1, parentSection);

      // Hit the api
      const { response } = await updateTest({
        testId: updatedTestSettings._id,
        settings: {
          sections
        }
      });
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [testSettings, updateTest]
  );

  // Update a sub section
  const updateSubSection_ = useCallback(
    async (newSubSectionObject, sectionId) => {
      // Ensures the previous data is not mutated;
      const updatedTestSettings = { ...testSettings };

      // Get parent section index
      const settingObjectIndex = findIndex(updatedTestSettings.sections, {
        _id: sectionId
      });
      const { sections } = updatedTestSettings;

      // Get parent section & current subsection index.
      const parentSection = find(sections, { _id: sectionId });
      const subSettingsObjectIndex = findIndex(parentSection.subsections, {
        _id: newSubSectionObject._id
      });

      // Replace the edited sub-section in parent section
      parentSection.subsections[subSettingsObjectIndex] = {
        ...parentSection.subsections[subSettingsObjectIndex],
        ...newSubSectionObject
      };
      // parentSection.subsections.splice(subSettingsObjectIndex, 1, newSubSectionObject); // Old approach

      // Replace the parent section with updated section w/new sub-section
      sections.splice(settingObjectIndex, 1, parentSection);

      // Hit the api
      const { response } = await updateTest({
        testId: updatedTestSettings._id,
        settings: {
          sections
        }
      });
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [testSettings, updateTest]
  );

  // Delete sub-section
  const deleteSubSection = useCallback(
    async (subsectionId, sectionId) => {
      // Get parent section index
      const settingObjectIndex = findIndex(testSettings.sections, {
        _id: sectionId
      });
      const { sections } = testSettings;

      // Get parent section & current subsection index.
      const parentSection = find(sections, { _id: sectionId });
      const subSettingsObjectIndex = findIndex(parentSection.subsections, {
        _id: subsectionId
      });

      // Remove the subsection from parent section
      parentSection.subsections.splice(subSettingsObjectIndex, 1);

      // Replace the parent section in sections array
      sections.splice(settingObjectIndex, 1, parentSection);

      // Hit the api
      const { response } = await updateTest({
        testId: testSettings._id,
        settings: {
          sections
        }
      });
      const { data } = response;
      if (data) setTestSettings({ ...data });
      return data;
    },
    [testSettings, updateTest]
  );

  return {
    testSettings,
    setTestSettings,
    createNewTest,
    updateCurrentTest,
    addNewSection,
    addNewSubsection,
    editCurrentSection,
    editCurrentSubsection,
    updateSectionsOrder,
    fetchSectionData,
    fetchSubsectionData,
    addNewSection_,
    updateSection_,
    deleteSection,
    normalizeTestSettings,
    addNewSubSection_,
    updateSubSection_,
    deleteSubSection
  };
};

const CreateTestContainer = createContainer(useCreateTest);

export default CreateTestContainer;
