import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { Global } from '@emotion/react';

import './styles/app.scss';

import { globals } from 'styles';
import Routes from 'globals/routes';
import Navigation from 'components/Navigation';
import { UserInfoContainer } from 'api/auth';
import { ROUTE_PATHS } from 'globals/constants';

import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';

import Layout from 'layouts';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  const user = UserInfoContainer.useContainer();

  const getNavLinks = (isAdmin) => {
    const defaultLinks = [
      {
        link: ROUTE_PATHS.home,
        text: 'Dashboard'
      },
      {
        link: ROUTE_PATHS.mediaLibrary,
        text: 'Media'
      }
    ];
    if (isAdmin) return [...defaultLinks, { link: ROUTE_PATHS.users, text: 'Users' }];
    return defaultLinks;
  };

  // const [userIDSet, isUserIDSet] = useState(false);
  // useEffect(() => {
  //   if (user.access_token) {
  //     isUserIDSet(true);
  //   } else {
  //     isUserIDSet(false);
  //   }
  // }, [user.access_token]);

  return (
    <Fragment>
      <Global styles={globals} />
      {user && user.token ? (
        <Navigation navLinks={getNavLinks(user.roles.includes('admin'))} />
      ) : (
        ''
      )}

      <Routes />
      <ReactQueryDevtools initialIsOpen={false} />
    </Fragment>
  );
};
const WithUserContainer = () => (
  <UserInfoContainer.Provider>
    <Layout>
      <App />
    </Layout>
  </UserInfoContainer.Provider>
);

const WithQueryClient = () => (
  <QueryClientProvider client={queryClient}>
    <WithUserContainer />
  </QueryClientProvider>
);

export default hot(WithQueryClient);
