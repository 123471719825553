import React, { Fragment, useEffect, useState } from 'react';

import { UserInfoContainer, useValidateToken, useLogout } from 'api/auth';

export default (props) => {
  const { validateToken, data } = useValidateToken();
  const { token } = UserInfoContainer.useContainer();
  const logout = useLogout();

  useEffect(() => {
    validateToken();
  }, [validateToken, token]);

  const [didLogout, updateDidLogout] = useState(false);
  useEffect(() => {
    const logoutAction = () => {
      if (data && data.status === 401) {
        logout();
        updateDidLogout(true);
        // window.location.reload();
      }
    };
    if (!didLogout) {
      logoutAction();
    }
  }, [data, didLogout, logout]);

  return <Fragment {...props} />;
};
