/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc } from 'styles/utilities';

export const dragBox = (props) => css`
  position: relative;
  width: ${props.fluidCell ? 'auti' : '50px'};
  height: ${props.fluidCell ? 'auti' : '50px'};
  background-color: ${colors.cream};
  border: ${misc.border.default};
  border-radius: ${misc.smallBorderRadius};
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    border-color: ${colors.primaryAnchorDefaultColor};
  }
  > * {
    ${props.fluidCell
      ? `
      padding: 10px 15px;
      display: block;
      `
      : `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  }
`;
