import { useState, useEffect } from 'react';
import { misc } from 'styles/utilities';

const BelowNavBreakpoint = ({ children }) => {
  const [isBelowNavBreakpoint, toggleBreakpointValue] = useState(
    window.innerWidth < parseInt(misc.layoutChangeBreakpoint())
  );

  function updateBreakpointValue() {
    if (window.innerWidth < parseInt(misc.layoutChangeBreakpoint())) {
      toggleBreakpointValue(true);
    } else {
      toggleBreakpointValue(false);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateBreakpointValue);
    return () => {
      window.removeEventListener('resize', updateBreakpointValue);
    };
  });

  return children(isBelowNavBreakpoint);
};

export default BelowNavBreakpoint;
