/** @jsxImportSource @emotion/react */
import React, { Fragment, useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Container, Row, Col } from 'components/Grid';
import H1 from 'components/htmlElements/H1';
import H4 from 'components/htmlElements/H4';
import A from 'components/htmlElements/A';
import Icon from 'components/Icon/';
import SimpleDropdown, { DropdownTrigger, DropdownContent } from 'components/SimpleDropdown';
import EmptySectionsBand from 'components/EmptySectionsBand';

import { Redirect, useNavigate } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';

import useSearchQuery from 'hooks/useSearchQuery';
import { useGetQuestion } from 'api/questions';
import QuestionsContainer from 'hooks/useCreateQuestion';
import LoaderOverlay from 'components/LoaderOverlay';
import {
  TextQuestionTemplate,
  TwoColumnComprehensionTemplate,
  AnagramQuestionTemplate,
  ShuffledSentencesQuestionTemplate,
  SpellingPunctuationQuestionTemplate,
  MathEquationAnagramsQuestionTemplate,
  ClozeQuestionTemplate,
  PictureImageQuestionTemplate,
  SelectedLettersTemplate,
  QUESTION_TYPE
} from 'components/QuestionTypes';

// Get initial states of question types
import { getInitialState as getInitialStateOfTextQuestion } from 'components/QuestionTypes/TextQuestion/helpers';
import { getInitialState as getInitialStateOfTwoColumnComprehension } from 'components/QuestionTypes/TwoColumnComprehension/helpers';
import { getInitialState as getInitialStateOfAnagramQuestion } from 'components/QuestionTypes/AnagramQuestion/helpers';
import { getInitialState as getInitialStateOfShuffledSentencesQuestion } from 'components/QuestionTypes/ShuffledSentencesQuestion/helpers';
import { getInitialState as getInitialStateOfSpellingPunctuationQuestion } from 'components/QuestionTypes/SpellingPunctuationQuestion/helpers';
import { getInitialState as getInitialStateOfMathEquationAnagramsQuestion } from 'components/QuestionTypes/MathEquationAnagramsQuestion/helpers';
import { getInitialState as getInitialStateOfClozeQuestion } from 'components/QuestionTypes/ClozeQuestion/helpers';
import { getInitialState as getInitialStateOfPictureImageQuestion } from 'components/QuestionTypes/PictureImageQuestion/helpers';
import { getInitialState as getInitialStateOfSelectedLetters } from 'components/QuestionTypes/SelectedLetters/helpers';

// Styling
import { bgColor, spacer } from 'styles/utilities';

// Constant
import * as styles from '../page.styles';

const { TextQuestion } = TextQuestionTemplate;
const { TwoColumnComprehension } = TwoColumnComprehensionTemplate;
const { AnagramQuestion } = AnagramQuestionTemplate;
const { ShuffledSentencesQuestion } = ShuffledSentencesQuestionTemplate;
const { SpellingPunctuationQuestion } = SpellingPunctuationQuestionTemplate;
const { MathEquationAnagramsQuestion } = MathEquationAnagramsQuestionTemplate;
const { ClozeQuestion } = ClozeQuestionTemplate;
const { PictureImageQuestion } = PictureImageQuestionTemplate;
const { SelectedLetters } = SelectedLettersTemplate;

const QuestionTypeComponent = ({ questionTypeSelected, ...props }) => {
  switch (questionTypeSelected) {
    case 'text':
      return (
        <TextQuestion
          {...props}
          initialState={getInitialStateOfTextQuestion(questionTypeSelected)}
        />
      );

    case 'image':
      return (
        <TextQuestion
          {...props}
          initialState={getInitialStateOfTextQuestion(questionTypeSelected)}
        />
      );

    case 'summary':
      return (
        <TextQuestion
          {...props}
          initialState={getInitialStateOfTextQuestion(questionTypeSelected)}
        />
      );

    case 'anagrams':
      return (
        <AnagramQuestion
          {...props}
          initialState={getInitialStateOfAnagramQuestion(questionTypeSelected)}
        />
      );

    case 'cloze':
      return (
        <ClozeQuestion
          {...props}
          initialState={getInitialStateOfClozeQuestion(questionTypeSelected)}
        />
      );

    case 'shuffledSentences':
      return (
        <ShuffledSentencesQuestion
          {...props}
          initialState={getInitialStateOfShuffledSentencesQuestion(questionTypeSelected)}
        />
      );

    case 'twoColComprehension':
      return (
        <TwoColumnComprehension
          {...props}
          initialState={getInitialStateOfTwoColumnComprehension(questionTypeSelected)}
        />
      );

    case 'anagramsMathEq':
      return (
        <MathEquationAnagramsQuestion
          {...props}
          initialState={getInitialStateOfMathEquationAnagramsQuestion(questionTypeSelected)}
        />
      );

    case 'spellingPunctuation':
      return (
        <SpellingPunctuationQuestion
          {...props}
          initialState={getInitialStateOfSpellingPunctuationQuestion(questionTypeSelected)}
        />
      );

    case 'sentenceCompletion':
      return (
        <TextQuestion
          {...props}
          initialState={getInitialStateOfTextQuestion(questionTypeSelected)}
        />
      );

    case 'pictureImage':
      return (
        <PictureImageQuestion
          {...props}
          initialState={getInitialStateOfPictureImageQuestion(questionTypeSelected)}
        />
      );

    case 'selectedLetters':
      return (
        <SelectedLetters
          {...props}
          initialState={getInitialStateOfSelectedLetters(questionTypeSelected)}
        />
      );

    default:
      return null;
  }
};
QuestionTypeComponent.propTypes = {
  questionTypeSelected: PropTypes.string
};
QuestionTypeComponent.defaultProps = {
  questionTypeSelected: ''
};

const QuestionTypeSettings = ({ waterfallSettings }) => {
  const { questions, deleteQuestion } = QuestionsContainer.useContainer();

  const { useParseQueryString, setQueryParams } = useSearchQuery();
  const queryStringData = useParseQueryString();

  // Get question data from api
  const [questionId, updateQuestionId] = useState(queryStringData.questionId);
  const { data: questionDataFromApi, status, error } = useGetQuestion(questionId);
  const [questionData, updateQuestionData] = useState(questionDataFromApi);
  useEffect(() => {
    updateQuestionData(questionDataFromApi);
  }, [questionDataFromApi]);

  const [dropdownSelectItem, setDropdownSelectItem] = useState(
    QUESTION_TYPE.filter((item) => item.type === questionDataFromApi?.data?.type)[0]?.niceName
  );
  const [questionTypeSelected, setQuestionTypeSelected] = useState(questionDataFromApi?.data?.type);
  // Also select the question type from the API response
  useEffect(() => {
    if (questionDataFromApi?.data?.type !== questionData?.data?.type) {
      setQuestionTypeSelected(questionDataFromApi?.data?.type);
      setDropdownSelectItem(
        QUESTION_TYPE.filter((item) => item.type === questionDataFromApi.data.type)[0]?.niceName
      );
    }
  }, [questionData?.data?.type, questionDataFromApi?.data?.type]);

  useEffect(() => {
    updateQuestionId(queryStringData.questionId);
  }, [queryStringData.questionId]);

  // Toggle overlay
  const [overlay, toggleOverlay] = useState(false);

  // Toggle question type dropdown
  const questionTypeDropdownRef = useRef(null);

  const navigate = useNavigate();
  const removeQuestionFromQueryString = useCallback(
    (updatedQueryString = queryStringData) => {
      navigate(
        `?${setQueryParams(
          {
            ...updatedQueryString
          },
          true
        )}`,
        { replace: true }
      );
    },
    [navigate, queryStringData, setQueryParams]
  );

  // Delete question function. Calling it here since it's common across all question types
  const handleQuestionDelete = async (questionIdToDelete) => {
    toggleOverlay(true);
    await deleteQuestion(questionIdToDelete);
    toggleOverlay(false);
    // Remove question from query string
    if (queryStringData.questionId) {
      delete queryStringData.questionId;
      removeQuestionFromQueryString(queryStringData);
    }
  };

  const handleQuestionChange = (event, questionType) => {
    event.preventDefault();

    setDropdownSelectItem(questionType.niceName);
    setQuestionTypeSelected(questionType.type);

    // Hide dropdown on click
    questionTypeDropdownRef.current.hide();
  };

  if (error) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (status !== 'success' || !questionData) {
    return <PageLoader isFetching isRelative height="400px" />;
  }

  // if (Object.keys(questionData) && questionData?.error) {
  //   removeQuestionFromQueryString();
  // }

  return (
    <div css={[bgColor.mediumGrey, spacer.padT20]} className="is-relative">
      {questionData && questions && !questions.length && questionTypeSelected ? (
        <Container css={spacer.mrT40}>
          <Row>
            <Col>
              <EmptySectionsBand questions />
            </Col>
          </Row>
        </Container>
      ) : (
        <Fragment>
          {questionId ? (
            <Fragment>
              <Container>
                <Row>
                  <Col className="col-12 col-md-4">
                    <SimpleDropdown ref={questionTypeDropdownRef}>
                      <DropdownTrigger>
                        <div className="d-flex align-items-center m-0">
                          <H4 workSans semiBold color="lighterBlue" className="m-0">
                            {dropdownSelectItem}
                          </H4>
                          <div css={spacer.mrL10}>
                            <Icon icon="chevron-bottom" size="20" color="#3391ff" />
                          </div>
                        </div>
                      </DropdownTrigger>
                      <DropdownContent
                        css={[
                          styles.dropdownContent({ width: '300px' }),
                          spacer.padBT10,
                          spacer.padLR10
                        ]}
                      >
                        {QUESTION_TYPE.map((questionType) => (
                          <A
                            as="span"
                            semiBold
                            color="lighterBlue"
                            onClick={(event) => handleQuestionChange(event, questionType)}
                            key={`item-${questionType.type}`}
                            className="dropdown-segment m-0 d-block"
                          >
                            {questionType.niceName}
                          </A>
                        ))}
                      </DropdownContent>
                    </SimpleDropdown>
                  </Col>
                </Row>
              </Container>
              <QuestionTypeComponent
                questionTypeSelected={questionTypeSelected}
                handleQuestionDelete={handleQuestionDelete}
                questionData={questionData.data}
                waterfallSettings={waterfallSettings}
              />
            </Fragment>
          ) : (
            <Container css={spacer.mrT40}>
              <Row>
                <Col>
                  <H1 className="text-center" color="blackTransparent20">
                    Select a Question
                  </H1>
                </Col>
              </Row>
            </Container>
          )}
        </Fragment>
      )}
      {overlay && <LoaderOverlay />}
    </div>
  );
};

QuestionTypeSettings.propTypes = {
  waterfallSettings: PropTypes.object
};
QuestionTypeSettings.defaultProps = {
  waterfallSettings: {}
};

// QuestionTypeSettings.whyDidYouRender = true;
export default QuestionTypeSettings;
