/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

// Components
import H4 from 'components/htmlElements/H4';
import P from 'components/htmlElements/P';
import StatusPill from 'components/StatusPill';
import { Link } from 'components/Router';

// Styling
import { spacer } from 'styles/utilities';
import * as styles from './ProductCard.styles';

const ProductCard = ({ title, details, isPublished, link, customColor }) => (
  <div css={[styles.cardContainer({ customColor }), spacer.padBT20, spacer.padLR20, spacer.mrT30]}>
    <div className="content h-100">
      <div>
        <H4 className="title">{title}</H4>
        <P className="details">{details}</P>
      </div>
      <div css={[spacer.mrT20]} className="d-flex align-item-center justify-content-between">
        <div>
          <StatusPill
            text={isPublished ? 'Published' : 'Draft'}
            color={isPublished ? 'green' : 'navyBlue'}
          />
        </div>
        <P as={Link} to={link} gtWalsheim medium color="primaryAnchorDefaultColor">
          View Details
        </P>
      </div>
    </div>
  </div>
);

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  isPublished: PropTypes.bool,
  customColor: PropTypes.string
};

ProductCard.defaultProps = {
  isPublished: false,
  customColor: ''
};

export default ProductCard;
