/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import H2 from 'components/htmlElements/H2';
import H4 from 'components/htmlElements/H4';
import Button from 'components/htmlElements/Button';
import { Container, Row, Col } from 'components/Grid';
import Modal, { useModal } from 'components/Modal';
import MediaThumbnail from 'components/MediaThumbnail';
import MediaUploader from 'components/MediaUploader';
import { useDeleteMedia } from 'api/media';

import * as modalStyles from 'components/Modal/Modal.styles';
import { spacer } from 'styles/utilities';
import * as styles from './page.styles';

function isImageUrl(url) {
  return url.match(/\.(jpeg|jpg|gif|png|svg|webp)$/gi) != null;
}

const FileType = ({ mediaFileUrl, queryParams, mediaFileName, hoverable }) =>
  isImageUrl(mediaFileUrl) ? (
    <MediaThumbnail
      url={`${mediaFileUrl}${queryParams.length ? `?${queryParams}` : ''}`}
      alt={mediaFileName}
      width="100%"
      hoverable={hoverable}
    />
  ) : (
    <div className="not-image-file">{mediaFileName}</div>
  );

FileType.propTypes = {
  mediaFileUrl: PropTypes.string.isRequired,
  mediaFileName: PropTypes.string.isRequired,
  queryParams: PropTypes.string,
  hoverable: PropTypes.bool
};

FileType.defaultProps = {
  hoverable: false,
  queryParams: ''
};

// This goes in the modal
const MediaViewer = ({ mediaFile, closeModal }) => {
  const { deleteMedia } = useDeleteMedia();

  const handleFileDelete = async (event, mediaId) => {
    closeModal();
    await deleteMedia(mediaId);
  };

  return (
    <Fragment>
      <div css={modalStyles.header}>
        <H4>File: {mediaFile.mediaFileName}</H4>
      </div>
      <div css={[spacer.mrLR20, spacer.mrT20]}>
        <FileType
          css={styles.fileInModal}
          mediaFileUrl={mediaFile.mediaFileUrl}
          queryParams="auto=format&w=620"
          mediaFileName={mediaFile.mediaFileName}
        />
        <div className="text-right" css={spacer.mrT20}>
          <Button onClick={(event) => handleFileDelete(event, mediaFile.mediaFileId)} secondary>
            Delete file
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
MediaViewer.propTypes = {
  mediaFile: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

// Main component
const MediaPage = ({ mediaFiles }) => {
  const {
    isOpen: mediaFileModalOpen,
    toggle: toggleMediaFileModal,
    openModal: openMediaFileModal,
    closeModal: closeMediaFileModal
  } = useModal();

  const {
    isOpen: mediaUploaderModalOpen,
    toggle: toggleMediaUploaderModal,
    openModal: openMediaUploaderModal
  } = useModal();

  const [currentMediaFile, toggleCurrentMediaFile] = useState({});

  const handleMediaCardClick = (event, mediaFile) => {
    toggleCurrentMediaFile({
      mediaFileId: mediaFile._id,
      mediaFileUrl: mediaFile.url,
      mediaFileName: mediaFile.originalName
    });

    openMediaFileModal(mediaFile._id);
  };

  return (
    <Fragment>
      <section css={spacer.mrBT60}>
        <Container>
          <Row>
            <Col css={spacer.mrB30}>
              <div className="d-flex justify-content-between align-items-center">
                <H2 workSans semiBold color="blackTransparent50">
                  Media Files ({mediaFiles.length})
                </H2>
                <Button onClick={openMediaUploaderModal}>Upload Media file</Button>
              </div>
            </Col>

            {mediaFiles.map((mediaFile) => (
              <Col className="col-6 col-xs-4 col-lg-3" key={mediaFile._id} css={spacer.mrB10}>
                <a href="#" onClick={(event) => handleMediaCardClick(event, mediaFile)}>
                  <FileType
                    mediaFileUrl={mediaFile.url}
                    mediaFileName={mediaFile.originalName}
                    queryParams="auto=format&w=250"
                    hoverable
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Modal isOpen={mediaFileModalOpen} toggle={toggleMediaFileModal}>
        <MediaViewer mediaFile={currentMediaFile} closeModal={closeMediaFileModal} />
      </Modal>
      <Modal isOpen={mediaUploaderModalOpen} toggle={toggleMediaUploaderModal} large>
        <MediaUploader />
      </Modal>
    </Fragment>
  );
};

MediaPage.propTypes = {
  mediaFiles: PropTypes.array
};

MediaPage.defaultProps = {
  mediaFiles: []
};

export default MediaPage;
