import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';

import { useGetTestSettings } from 'api/test';
import { useGetQuestions } from 'api/questions';

import CreateTestContainer from 'hooks/useCreateTest';
import { useGetCurrentTestLevel } from 'pages/CreateQuestion/container';
import QuestionsContainer from 'hooks/useCreateQuestion';

import { ROUTE_PATHS } from 'globals/constants';

import PageLoader from 'components/PageLoader';
import PreviewQuestions from './page';

const PreviewQuestionsContainer = () => {
  const { testSettings } = CreateTestContainer.useContainer();

  // Get info on the test structure to fetch questions accordingly
  const { levelId, level } = useGetCurrentTestLevel(testSettings);

  const { data: questions, error: questionsError, status: questionsStatus } = useGetQuestions(
    levelId,
    level
  );

  if (questionsError || (questions && questions.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (questionsStatus !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (questions) {
    return (
      <QuestionsContainer.Provider initialState={questions.data}>
        <PreviewQuestions />
      </QuestionsContainer.Provider>
    );
  }

  return null;
};

const WithTestSettingsProvider = ({ testId }) => {
  const { data: test, error: testError, status: testStatus } = useGetTestSettings(testId);

  if (testError || (test && test.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (testStatus !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (test) {
    return (
      <CreateTestContainer.Provider initialState={test.data}>
        <PreviewQuestionsContainer />
      </CreateTestContainer.Provider>
    );
  }

  return null;
};

WithTestSettingsProvider.propTypes = {
  testId: PropTypes.string.isRequired
};

export default WithTestSettingsProvider;
