/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react';

// Components
import Button from 'components/htmlElements/Button';
import H2 from 'components/htmlElements/H2';
import A from 'components/htmlElements/A';
import {
  FieldWrapper,
  FieldLabel,
  Textarea,
  Input,
  FieldError
} from 'components/htmlElements/Fields';
import { Container, Row, Col } from 'components/Grid';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import * as visualizerCard from 'components/VisualizerCard/variants/variants';
import H4 from 'components/htmlElements/H4';
import InlineToast from 'components/InlineToast';
import VisualizerCard from 'components/VisualizerCard';
import H5 from 'components/htmlElements/H5';
import { navigate } from 'components/Router';

// Constants
import { COURSE_STRUCTURE } from 'globals/constants';
import CourseContainer from 'hooks/useCreateCourse';

// Styling
import { spacer, bgColor } from 'styles/utilities';
import * as styles from './page.styles';

const CourseSchema = Yup.object().shape({
  name: Yup.string().required('Course name required'),
  description: Yup.string(),
  courseStructure: Yup.string().required('Course structure required')
});

const CreateCourse = () => {
  const createCourseContainer = CourseContainer.useContainer();
  const { courseSettings } = createCourseContainer;
  const { _id: courseId } = courseSettings;

  const [editCourse, setEditCourse] = useState(false);
  const [submitDraft, setSubmitDraft] = useState(false);
  const [isInDraftMode, updateIsInDraftMode] = useState(courseSettings.isDraft);

  useEffect(() => {
    if (courseId) {
      setEditCourse(true);
      updateIsInDraftMode(courseSettings.isDraft);
    }
  }, [courseId, courseSettings.isDraft]);

  const saveAndPublish = async (values) => {
    if (editCourse) {
      let changedSettings = createCourseContainer.normalizeCourseSettings(values, true);
      changedSettings = { ...changedSettings, isDraft: false };
      const data = await createCourseContainer.updateCurrentCourse(courseId, changedSettings);
      if (data) navigate(`/course/${data._id}`, { replace: true });
    } else {
      let settings = createCourseContainer.normalizeCourseSettings(values);
      settings = { ...settings, isDraft: false };
      const data = await createCourseContainer.createNewCourse(settings);
      if (data) navigate(`/course/${data._id}`, { replace: true });
    }
  };

  const saveAsDraft = async (values) => {
    if (editCourse) {
      let changedSettings = createCourseContainer.normalizeCourseSettings(values, true);
      changedSettings = { ...changedSettings, isDraft: true };
      const data = await createCourseContainer.updateCurrentCourse(courseId, changedSettings);
      setSubmitDraft(false);
      if (data) navigate(`/course/${data._id}`, { replace: true });
    } else {
      let settings = createCourseContainer.normalizeCourseSettings(values);
      settings = { ...settings, isDraft: true };
      const data = await createCourseContainer.createNewCourse(settings);
      setSubmitDraft(false);
      if (data) navigate(`/course/${data._id}`, { replace: true });
    }
  };

  const [isEdit, setisEdit] = useState(false);

  useEffect(() => {
    if (courseId) {
      setisEdit(true);
    }
  }, [courseId, isEdit]);

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    if (submitDraft) {
      await saveAsDraft(values);
    } else {
      await saveAndPublish(values);
    }
    actions.setSubmitting(false);
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          name: courseSettings.name,
          description: courseSettings.description,
          courseStructure: courseSettings.courseStructure,
          color: courseSettings.meta?.color
        }}
        validationSchema={CourseSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting, submitForm }) => (
          <Form>
            <div css={[bgColor.mediumGrey]}>
              <Container css={spacer.mrB80}>
                <Row className="d-flex justify-content-center">
                  <Col className="col-lg-6">
                    {/* Create New Course Header */}
                    <H2 workSans semiBold css={[spacer.padT60, spacer.mrB40]}>
                      {editCourse ? 'Edit' : 'Create New'} Course
                    </H2>

                    {/* Course name */}
                    <FieldWrapper>
                      <FieldLabel className="placeholder" htmlFor="name" required>
                        Course name
                      </FieldLabel>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        as={Input}
                        placeholder="Course name"
                      />
                      {errors.name && touched.name && <FieldError>{errors.name}</FieldError>}
                    </FieldWrapper>

                    <FieldWrapper>
                      <FieldLabel className="placeholder" htmlFor="name">
                        Description (Optional)
                      </FieldLabel>
                      <Field
                        type="text"
                        name="description"
                        id="description"
                        as={Textarea}
                        placeholder="Enter short description for this course"
                      />
                      {errors.description && touched.description && (
                        <FieldError>{errors.description}</FieldError>
                      )}
                    </FieldWrapper>

                    <FieldWrapper>
                      <FieldLabel className="placeholder">Color (Optional)</FieldLabel>
                      <br />
                      <Field type="color" name="color" />
                      <H5 workSans>{values.color}</H5>
                    </FieldWrapper>
                  </Col>

                  {/* Divider */}
                  <Col>
                    <div css={[styles.sectionDivider, spacer.mrT40, spacer.mrB60]} />
                  </Col>

                  <Col className="col-lg-6">
                    {/* Section settings */}
                    <H4 workSans css={spacer.mrB30}>
                      Section settings
                    </H4>

                    {/* Course section structure description */}
                    <Row css={[styles.settings, spacer.padB30]} className="last-child">
                      <Col>
                        <H5 workSans>Select course section structure</H5>
                        <InlineToast
                          type="error"
                          message="You cannot change this after you’ve started adding lessons, so choose carefully!"
                        />
                      </Col>
                    </Row>
                  </Col>

                  {/* Section Visualizer Card */}
                  <Col className="col-lg-9">
                    <Row css={[styles.settings, spacer.padB30]} className="no-border">
                      <Col className="col-lg-4">
                        <label htmlFor="courseStructure-test">
                          <Field
                            id="courseStructure-test"
                            type="radio"
                            css={styles.label}
                            name="courseStructure"
                            value={COURSE_STRUCTURE.course}
                            disabled={editCourse}
                          />
                          <VisualizerCard
                            course
                            variant={visualizerCard.COURSE_NO_SECTIONS}
                            selected={values.courseStructure === COURSE_STRUCTURE.course}
                          />
                        </label>
                      </Col>
                      <Col className="col-lg-4">
                        <label htmlFor="courseStructure-sections">
                          <Field
                            id="courseStructure-sections"
                            type="radio"
                            css={styles.label}
                            name="courseStructure"
                            value={COURSE_STRUCTURE.sections}
                            disabled={editCourse}
                          />
                          <VisualizerCard
                            course
                            variant={visualizerCard.COURSE_SINGLE_SECTIONS}
                            selected={values.courseStructure === COURSE_STRUCTURE.sections}
                          />
                        </label>
                      </Col>
                      <Col className="col-lg-4">
                        <label htmlFor="courseStructure-subsections">
                          <Field
                            id="courseStructure-subsections"
                            type="radio"
                            css={styles.label}
                            name="courseStructure"
                            value={COURSE_STRUCTURE.subsections}
                            disabled={editCourse}
                          />
                          <VisualizerCard
                            course
                            variant={visualizerCard.COURSE_SECTIONS_AND_SUBSECTIONS}
                            selected={values.courseStructure === COURSE_STRUCTURE.subsections}
                          />
                        </label>
                      </Col>
                    </Row>
                    {errors.courseStructure && touched.courseStructure && (
                      <FieldError>{errors.courseStructure}</FieldError>
                    )}
                  </Col>
                </Row>
              </Container>
              {/* Save settings section */}
            </div>

            <div className="save-settings" css={[styles.saveSettings, spacer.padBT20]}>
              <Container>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {!isSubmitting && (
                      <A href="#" onClick={() => navigate(-1)}>
                        Cancel
                      </A>
                    )}
                  </div>

                  <div className="d-flex">
                    {(!editCourse || isInDraftMode) && (
                      <div>
                        <Button
                          loading={isSubmitting && submitDraft}
                          disabled={isSubmitting && !submitDraft}
                          type="button"
                          onClick={() => {
                            setSubmitDraft(true);
                            submitForm();
                          }}
                        >
                          Save as Draft
                        </Button>
                      </div>
                    )}

                    {editCourse && (
                      <div css={spacer.mrL20}>
                        <Button
                          outline={isInDraftMode}
                          type="button"
                          loading={isSubmitting && !submitDraft}
                          disabled={isSubmitting && submitDraft}
                          onClick={() => {
                            setSubmitDraft(false);
                            submitForm();
                          }}
                        >
                          {editCourse && !isInDraftMode ? 'Save and Continue' : 'Publish'}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default CreateCourse;
