/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';

import H5 from 'components/htmlElements/H5';
import Icon from 'components/Icon';
import Dropdown, { DropdownTrigger, DropdownContent } from 'components/SimpleDropdown';
import ProfileIcon from 'components/ProfileIcon';
import ProfileModal from 'components/ProfileModal';
import Modal, { useModal } from 'components/Modal';
import A from 'components/htmlElements/A';
import { simpleDropdownStyledItem } from 'components/SimpleDropdown/SimpleDropdown.styles';
import useChevron from 'hooks/useChevron';
import { useLogout, UserInfoContainer } from 'api/auth';

import * as styles from './NavProfile.styles';

const NavProfile = () => {
  const { chevron, toggleChevronDirection } = useChevron();
  const { isOpen: isProfileOpen, toggle: toggleProfileModal } = useModal();

  const user = UserInfoContainer.useContainer();
  const logout = useLogout();

  return (
    <Fragment>
      <Dropdown
        css={styles.navProfileDropdown}
        onShow={() => {
          toggleChevronDirection('chevron-top');
        }}
        onHide={() => {
          toggleChevronDirection('chevron-bottom');
        }}
      >
        <DropdownTrigger className="nav-profile-dropdown-trigger">
          <div className="d-flex align-items-center">
            <H5 as="span" color="white" gtWalsheim bold css={styles.name}>
              {user.name.split(/ (.+)/)[0]}
            </H5>
            <ProfileIcon
              type="text"
              firstName={user.name.split(/ (.+)/)[0]}
              lastName={user.name.split(/ (.+)/)[1]}
              image={require('images/logo-emblem.png')}
            />
            <Icon icon={chevron} size="1.8em" className="icon" color="white" />
          </div>
        </DropdownTrigger>
        <DropdownContent className="nav-profile-dropdown-contents">
          <A
            css={simpleDropdownStyledItem}
            className="dropdown-segment"
            nounderline
            onClick={(e) => {
              e.preventDefault();
              toggleProfileModal();
            }}
          >
            <H5 as="span" gtWalsheim bold>
              Profile
            </H5>
          </A>
          <A
            css={simpleDropdownStyledItem}
            className="dropdown-segment"
            nounderline
            href="#"
            onClick={(e) => {
              e.preventDefault();
              logout();
            }}
          >
            <H5 as="span" gtWalsheim bold>
              Logout
            </H5>
          </A>
        </DropdownContent>
      </Dropdown>
      <Modal isOpen={isProfileOpen} toggle={toggleProfileModal}>
        <ProfileModal closeModal={toggleProfileModal} userInfo={user && user} />
      </Modal>
    </Fragment>
  );
};

export default NavProfile;
