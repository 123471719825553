/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc, spacer } from 'styles/utilities';

export const band = css`
  border: solid 2px ${colors.blackTransparent10};
  border-radius: ${misc.largeBorderRadius};
  background-color: ${colors.white};
  ${spacer.padLR30};
  ${spacer.padBT30};
  ${spacer.mrB20};
  &.borderless {
    border: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    .drag-icon-wrapper {
      display: none;
    }
    .settings-content-wrapper {
      margin: 0;
    }
  }
`;

export const ctasWrapper = css`
  border-top: solid 1px ${colors.blackTransparent10};
  > * {
    margin-right: 20px;
  }
  ${'' /* ${spacer.padLR30}; */}
  ${spacer.padT15};
  ${spacer.mrT30};
`;
