import React, { Fragment } from 'react';
import { ROUTE_PATHS } from 'globals/constants';

import { Redirect } from 'components/Router';
import GetTestsContainer from 'api/tests';
import GetCoursesContainer from 'api/courses';
import PageLoader from 'components/PageLoader';

import HomePage from './page';

const HomeContainer = () => {
  const { data: tests, error: testsError, status: testsStatus } = GetTestsContainer.useContainer();
  const {
    data: courses,
    error: coursesError,
    status: coursesStatus
  } = GetCoursesContainer.useContainer();

  if (testsError || coursesError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  return (
    <Fragment>
      {testsStatus !== 'success' && coursesStatus !== 'success' && (
        <PageLoader isFetching isRelative atPageHeight />
      )}
      <HomePage
        tests={tests.data}
        courses={courses.data}
        testsStatus={testsStatus}
        coursesStatus={coursesStatus}
      />
    </Fragment>
  );
};
export default () => (
  <GetTestsContainer.Provider initialState={{}}>
    <GetCoursesContainer.Provider>
      <HomeContainer />
    </GetCoursesContainer.Provider>
  </GetTestsContainer.Provider>
);
