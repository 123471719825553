import React, { Fragment } from 'react';
import { ROUTE_PATHS } from 'globals/constants';

import { Redirect } from 'components/Router';
import { useGetAllMedia } from 'api/media';
import PageLoader from 'components/PageLoader';
import { Waypoint } from 'react-waypoint';

import MediaPage from './page';

const MediaLibraryContainer = () => {
  const {
    data,
    isError,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useGetAllMedia();

  if (isError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (isLoading) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (data) {
    return (
      <Fragment>
        <MediaPage mediaFiles={data.data} />;
        <Waypoint
          bottomOffset="-40%"
          onEnter={() => {
            if (hasNextPage) {
              fetchNextPage();
            }
          }}
        />
        {isFetchingNextPage && <PageLoader isFetching isRelative height="150px" />}
      </Fragment>
    );
  }

  return null;
};
export default () => <MediaLibraryContainer />;
