/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import H5 from 'components/htmlElements/H5';
import A from 'components/htmlElements/A';
import NavLink from 'components/NavLink';

import * as styles from './MainNav.styles';

const MainNav = ({ navLinks }) => (
  <ul css={styles.navList} className="d-flex d-align-center">
    {navLinks.map((navItem) => (
      <li css={styles.navListItem} key={navItem.link}>
        <A as={NavLink} className="nav-link" nounderline to={navItem.link}>
          <H5 as="span" color="white" gtWalsheim bold>
            {navItem.text}
          </H5>
        </A>
      </li>
    ))}
  </ul>
);

MainNav.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired
};

export default MainNav;
