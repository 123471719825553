/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import QuestionsContainer from 'hooks/useCreateQuestion';
import QuestionBand from 'components/QuestionBand';
import { useParams, navigate } from 'components/Router';

import { Container } from 'components/Grid';

// Styling
import { bgColor, spacer } from 'styles/utilities';

import CreateTestContainer from 'hooks/useCreateTest';
import useSearchQuery from 'hooks/useSearchQuery';
import { TEST_STRUCTURE } from 'globals/constants';
import { useSectionSettingsContext } from 'components/SectionSettingsForm/SectionSettingsContext';
import { useSubSectionSettingsContext } from 'components/SubSectionSettingsForm/SubSectionSettingsContext';

import SectionSummaryBand from 'components/SectionSummaryBand';
import LoaderOverlay from 'components/LoaderOverlay';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';

const QuestionSectionHeader = ({ waterfallSettings }) => {
  const routeParams = useParams();
  const { questions, createNewQuestion } = QuestionsContainer.useContainer();

  const {
    testSettings,
    fetchSectionData,
    fetchSubsectionData
  } = CreateTestContainer.useContainer();
  const { useParseQueryString, setQueryParams } = useSearchQuery();
  const {
    editSectionSettings,
    toggleHideDeleteOption: sectionHideDelete
  } = useSectionSettingsContext();
  const {
    editSubSectionSettings,
    toggleHideDeleteOption: subSectionHideDelete
  } = useSubSectionSettingsContext();

  const queryStringData = useParseQueryString();

  const [isSection, toggleIsSection] = useState(
    testSettings.questionStructure === TEST_STRUCTURE.sections
  );
  const [isSubSection, toggleIsSubSection] = useState(
    testSettings.questionStructure === TEST_STRUCTURE.subsections
  );
  useEffect(() => {
    toggleIsSection(testSettings.questionStructure === TEST_STRUCTURE.sections);
    toggleIsSubSection(testSettings.questionStructure === TEST_STRUCTURE.subsections);
  }, [testSettings.questionStructure]);

  // Update settings to display based on test structure
  const [sectionSummaryProps, updateSectionSummaryProps] = useState({ data: testSettings });
  useEffect(() => {
    updateSectionSummaryProps(() => {
      if (isSection || isSubSection) {
        const sectionIdWithFallback = queryStringData.sectionId || testSettings.sections[0]._id;

        const sectionData = fetchSectionData(testSettings, sectionIdWithFallback);
        // Returning sub-section data
        if (isSubSection) {
          const subSectionIdWithFallback =
            queryStringData.subsectionId || testSettings.sections[0].subsections[0]._id;
          return {
            data: fetchSubsectionData(sectionData, subSectionIdWithFallback),
            isSubSection: true,
            parentSectionId: sectionIdWithFallback,
            parentSectionName: sectionData?.name,
            hideAddQuestions: true,
            borderless: true,
            onHandleClick: () => {
              // Hide delete button.
              // An edge case where deleting the last section/subsection will throw and error on questions page
              subSectionHideDelete(true);

              editSubSectionSettings(sectionIdWithFallback, subSectionIdWithFallback);
            }
          };
        }

        // Returning section data
        return {
          data: sectionData,
          hideAddQuestions: true,
          borderless: true,
          onHandleClick: () => {
            // Hide delete button.
            // An edge case where deleting the last section/subsection will throw and error on questions page
            sectionHideDelete(true);

            editSectionSettings(queryStringData.sectionId);
          }
        };
      }

      // Returning test data
      return {
        data: testSettings,
        hideAddQuestions: true,
        borderless: true,
        onHandleClick: () => {
          navigate(`/test/${routeParams.testId}/settings`);
        }
      };
    });
  }, [
    editSectionSettings,
    editSubSectionSettings,
    fetchSectionData,
    fetchSubsectionData,
    isSection,
    isSubSection,
    queryStringData.sectionId,
    queryStringData.subsectionId,
    testSettings,
    routeParams.testId,
    sectionHideDelete,
    subSectionHideDelete
  ]);

  // Toggle overlay
  const [overlay, toggleOverlay] = useState(false);

  const handleNewQuestionOnClick = async () => {
    let body = {
      type: 'text',
      testId: testSettings._id,
      text: { questionSettings: { defaultMarks: waterfallSettings.defaultMarks } },
      usage: 'final'
    };

    const queryParams = {};

    if (isSection) {
      body = {
        ...body,
        atLevelSectionId: queryStringData.sectionId
      };
      queryParams.sectionId = queryStringData.sectionId;
    } else if (isSubSection) {
      body = {
        ...body,
        atLevelSubsectionId: queryStringData.subsectionId
      };
      queryParams.sectionId = queryStringData.sectionId;
      queryParams.subsectionId = queryStringData.subsectionId;
    }

    toggleOverlay(true);
    const data = await createNewQuestion(body);
    toggleOverlay(false);

    if (data) {
      queryParams.questionId = data._id;

      navigate(`?${setQueryParams(queryParams)}`);
    }
  };

  // Go to last question in the selected list of questions
  if (!queryStringData.questionId && questions.length) {
    navigate(
      `?${setQueryParams({ ...queryStringData, questionId: questions[questions.length - 1]._id })}`,
      { replace: true }
    );
  }

  const getCrumbs = () => {
    let crumbArray = [{ name: testSettings.name, link: `/test/${testSettings._id}` }];
    if (isSection || isSubSection) {
      if (isSubSection) {
        crumbArray = [...crumbArray, { name: sectionSummaryProps.parentSectionName }];
      }
      crumbArray = [...crumbArray, { name: sectionSummaryProps.data.name }];
    }
    return [...crumbArray, { name: 'Questions' }];
  };

  return (
    <Fragment>
      <div css={[bgColor.white]} className="is-relative">
        <Container css={[bgColor.white, spacer.padB20]}>
          <div css={spacer.padT30}>
            <Breadcrumb routes={getCrumbs()} />
          </div>
          <SectionSummaryBand {...sectionSummaryProps} />

          <QuestionBand handleNewQuestionOnClick={handleNewQuestionOnClick} />
        </Container>
        {overlay && <LoaderOverlay />}
      </div>
    </Fragment>
  );
};

QuestionSectionHeader.propTypes = {
  waterfallSettings: PropTypes.object
};
QuestionSectionHeader.defaultProps = {
  waterfallSettings: {}
};

export default QuestionSectionHeader;
