import { useMemo } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { api } from 'globals/constants';
import request from 'utils/request';
import { createContainer } from 'unstated-next';

export const useGetTests = ({ getAllVersions, testGroupId, isDraft, tupleKey = '' }) => {
  const payload = {};

  if (getAllVersions) payload.getAllVersions = getAllVersions;
  if (testGroupId) payload.testGroupId = testGroupId;
  if (isDraft) payload.isDraft = isDraft;

  // payload.getAllVersions = true;

  const { data, error, status, isLoading, isError, isSuccess } = useQuery(
    ['getTests', { tupleKey }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.tests.get,
        payload
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const memoTestsData = useMemo(() => data || {}, [data]);

  return {
    data: memoTestsData,
    error,
    status,
    isLoading,
    isError,
    isSuccess
  };
};

const GetTestsContainer = createContainer(useGetTests);

export const useSetDefaultVersion = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (testId) => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.test.setCurrentVersion(testId)
      });
      return response;
    },
    {
      onSettled(newData) {
        queryClient.refetchQueries(['getTests', { tupleKey: newData.data.testGroupId }]);
      }
    }
  );

  return {
    setDefaultVersion: mutate,
    isLoading,
    isError,
    isSuccess
  };
};

export default GetTestsContainer;
