/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc } from 'styles/utilities';
import { mix } from 'polished';

export const cardContainer = (props) => css`
  border-radius: ${misc.largeBorderRadius};
  border: ${misc.border.default};
  background-color: ${colors.white};
  overflow: hidden;
  ${props?.customColor &&
  css`
    border-color: ${props.customColor};
    background-color: ${mix('0.2', props.customColor, '#ffffff')};
  `};
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title,
  .details {
    margin: 0;
  }
  .details {
    color: ${colors.blackTransparent60};
  }
`;
