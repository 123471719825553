/** @jsxImportSource @emotion/react */
import React from 'react';

import Dropdown, { DropdownTrigger, DropdownContent } from 'components/SimpleDropdown';
import H5 from 'components/htmlElements/H5';
import A from 'components/htmlElements/A';
import Icon from 'components/Icon';
import { simpleDropdownStyledItem } from 'components/SimpleDropdown/SimpleDropdown.styles';
import NavLink from 'components/NavLink';
import useChevron from 'hooks/useChevron';

import * as styles from './MainNavMobile.styles';

const MainNavMobile = () => {
  const { chevron, toggleChevronDirection } = useChevron();
  return (
    <div css={styles.dropdownWrapper}>
      <Dropdown
        css={styles.mainNavDropdown}
        onShow={() => {
          toggleChevronDirection('chevron-top');
        }}
        onHide={() => {
          toggleChevronDirection('chevron-bottom');
        }}
      >
        <DropdownTrigger>
          <div css={styles.activeItem}>
            <H5 as="span" color="white" gtWalsheim bold>
              Dashboard
            </H5>
            <Icon icon={chevron} size="1.8em" className="icon" color="white" />
          </div>
        </DropdownTrigger>
        <DropdownContent className="main-nav-dropdown-content">
          <A
            as={NavLink}
            css={simpleDropdownStyledItem}
            className="dropdown-segment"
            nounderline
            to="/"
          >
            <H5 as="span" gtWalsheim bold>
              My Profile
            </H5>
          </A>
        </DropdownContent>
      </Dropdown>
    </div>
  );
};

export default MainNavMobile;
