/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { customAlphabet } from 'nanoid';

// components
import A from 'components/htmlElements/A';
import P from 'components/htmlElements/P';
import Button from 'components/htmlElements/Button';
import Checkbox from 'components/Checkbox';
import RichTextEditor from 'components/RichTextEditor';
import { NANO_ID_ALPHANUMERICS } from 'globals/constants';

import { FieldArray, Field } from 'formik';

// styling
import { spacer } from 'styles/utilities';

const nanoid = customAlphabet(NANO_ID_ALPHANUMERICS, 10);

const RepeaterOptions = ({ setFieldValue, values }) => {
  const NEW_OPTION_TEMPLATE = {
    text: '',
    shortId: nanoid(),
    isCorrect: false // This is only for internal app reference to set `answer` key
  };

  return (
    <div>
      <FieldArray name="answerOptions">
        {({ remove, push }) => (
          <Fragment>
            {values.answerOptions.length > 0 &&
              values.answerOptions.map((answerOption, index) => (
                <div css={spacer.mrB30} key={index}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    css={spacer.mrB10}
                  >
                    <div className="d-flex align-items-center">
                      <P workSans small semiBold>
                        Option {index + 1}
                      </P>
                      <div css={spacer.mrL30}>
                        <Field
                          as={Checkbox}
                          name={`answerOptions.${index}.isCorrect`}
                          checked={answerOption.isCorrect}
                          label="Correct answer"
                        />
                      </div>
                    </div>
                    <A semiBold color="red" as="span" onClick={() => remove(index)}>
                      Delete option
                    </A>
                  </div>
                  <Field
                    name={`answerOptions.${index}.text`}
                    as={RichTextEditor}
                    value={answerOption.text}
                    onChange={(data) => {
                      setFieldValue(`answerOptions.${index}.text`, data);
                      // setFieldValue(`answerOptions.${index}.shortId`, answerOption.shortId);
                    }}
                  />
                  <Field
                    type="hidden"
                    value={answerOption.shortId}
                    name={`answerOptions.${index}.shortId`}
                  />
                </div>
              ))}
            <div css={spacer.mrT10} className="d-flex justify-content-end">
              <Button onClick={() => push(NEW_OPTION_TEMPLATE)} type="button">
                + Add option
              </Button>
            </div>
          </Fragment>
        )}
      </FieldArray>
    </div>
  );
};

RepeaterOptions.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default RepeaterOptions;
