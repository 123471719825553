import { TEST_STRUCTURE, EXTRA_TIME_TYPES } from 'globals/constants';
import { map, merge, forEach, omit } from 'lodash-es';
import convertTimeDuration from 'utils/convertTimeDuration';
import isNotUndefined from 'utils/isNotUndefined';

const yupValidations = {
  defaultRequired: 'Required',
  defaultMarks: 'Default marks required',
  defaultNegativeMarks: 'Negative marks cannot be empty',
  questionStructure: 'Question structure is required',
  resultsDisplayedAt: 'Field required',
  timeLimit: 'Test duration is required'
};

function settingItemInfo({ settingKey, settingValue }) {
  switch (settingKey) {
    case '_id':
      return {
        value: settingValue,
        text: 'ID',
        positiveStatus: '',
        negativeStatus: '',
        input: {
          type: 'hidden'
        }
      };

    case 'name':
      return {
        value: settingValue,
        text: 'Section Name',
        positiveStatus: settingValue,
        negativeStatus: '',
        input: {
          type: 'text'
        }
      };

    case 'markingEnabled':
      return {
        value: settingValue,
        text: 'Default Marking',
        positiveStatus: 'Enabled',
        negativeStatus: 'Disabled',
        input: {
          type: 'hidden'
        }
      };

    case 'defaultMarks':
      return {
        value: settingValue,
        text: 'Default Marks',
        positiveStatus: `Yes, ${settingValue} Marks`,
        negativeStatus: '-',
        input: {
          type: 'number',
          min: 0
        }
      };

    case 'negativeMarkingEnabled':
      return {
        value: settingValue,
        text: 'Negative Marking Enabled',
        positiveStatus: 'Enabled',
        negativeStatus: 'Disabled',
        input: {
          type: 'checkbox'
        }
      };

    case 'defaultNegativeMarks':
      return {
        value: settingValue,
        text: 'Negative Marks',
        positiveStatus: `${settingValue} Marks`,
        negativeStatus: '-',
        input: {
          type: 'number',
          min: 0
        }
      };

    case 'allowSkipQuestions':
      return {
        value: settingValue,
        text: 'Skipping Questions',
        positiveStatus: 'Enabled',
        negativeStatus: 'Disabled',
        input: {
          type: 'checkbox'
        }
      };

    case 'allowAnswerEditing':
      return {
        value: settingValue,
        text: 'Editable Answers',
        positiveStatus: 'Yes',
        negativeStatus: 'No',
        input: {
          type: 'checkbox'
        }
      };

    case 'allowBackAndForthNavigation':
      return {
        value: settingValue,
        text: 'Question navigation',
        positiveStatus: 'Yes',
        negativeStatus: 'No',
        input: {
          type: 'checkbox'
        }
      };

    case 'enableLocking':
      return {
        value: settingValue,
        text: 'Enable locking',
        positiveStatus: 'Yes',
        negativeStatus: 'No',
        input: {
          type: 'checkbox'
        }
      };

    case 'enablePausingTimer':
      return {
        value: settingValue,
        text: 'Allow Manual Pausing',
        positiveStatus: 'Yes',
        negativeStatus: 'No',
        input: {
          type: 'checkbox'
        }
      };

    case 'enableTimer':
      return {
        value: settingValue,
        text: 'Timer',
        positiveStatus: 'Enabled',
        negativeStatus: 'Disabled',
        input: {
          type: 'checkbox'
        }
      };

    case 'timeLimit':
      return {
        value: settingValue,
        text: 'Time limit',
        positiveStatus: `${settingValue} mins`,
        negativeStatus: 'Disabled',
        input: {
          type: 'number',
          min: 0
        },
        meta: {
          suffix: 'mins'
        }
      };

    case 'allowedExtraTime':
      return {
        value: settingValue,
        text: 'Extra Time',
        positiveStatus: settingValue,
        negativeStatus: '-',
        input: {
          type: 'number',
          min: 0
        }
      };

    case 'allowedExtraTimeType':
      return {
        value: settingValue,
        text: 'Extra Time Type',
        positiveStatus: settingValue,
        negativeStatus: '-',
        input: {
          type: 'text'
        }
      };

    case 'timerAtQuestion':
      return {
        value: settingValue,
        text: 'Timer at question',
        positiveStatus: 'Yes',
        negativeStatus: 'No',
        input: {
          type: 'checkbox'
        }
      };

    case 'showReportToCandidate':
      return {
        value: settingValue,
        text: 'Reports',
        positiveStatus() {
          if (TEST_STRUCTURE.subsections) {
            return 'On Sub Sections';
          }
          if (TEST_STRUCTURE.sections) {
            return 'On Sections';
          }
          return 'On Tests';
        },
        negativeStatus: 'Disabled',
        input: {
          type: 'checkbox'
        }
      };

    default:
      return {};
  }
}

function getTestSettingsFormFields({ testSettings = {} }) {
  const flattenTestSettings = {
    ...testSettings?.markingSettings,
    ...testSettings?.settings
    // timeLimit: testSettings?.timeLimit,
    // timerAtQuestion: testSettings?.timerAtQuestion
  };
  const testSettingsInfo = map(flattenTestSettings, (settingValue, settingKey) => ({
    [settingKey]: settingItemInfo({ settingValue, settingKey })
  }));

  const testSettingsToMerge = testSettingsInfo.length ? Object.assign(...testSettingsInfo) : {};
  const mergedSettings = merge({}, testSettingsToMerge);

  return mergedSettings;
}

function getSectionSettingsFormFields({
  newSection = false,
  sectionSettings = {},
  testSettings = {},
  additionalFieldsToAdd = {}
}) {
  // Remove _id from the settings object to avoid API errors
  const __testSettings__ = testSettings;
  if (__testSettings__.settings) {
    __testSettings__.settings = omit(__testSettings__?.settings, '_id');
  }

  // Remove _id from the settings object to avoid API errors
  const __sectionSettings__ = sectionSettings;
  if (__sectionSettings__.settings) {
    __sectionSettings__.settings = omit(__sectionSettings__?.settings, '_id');
  }

  const flattenTestSettings = {
    ...__testSettings__?.markingSettings,
    ...__testSettings__?.settings
    // ...additionalFieldsToAdd
    // timeLimit: testSettings?.timeLimit,
    // timerAtQuestion: testSettings?.timerAtQuestion
  };
  const testSettingsInfo = map(flattenTestSettings, (settingValue, settingKey) => ({
    [settingKey]: settingItemInfo({ settingValue, settingKey })
  }));

  const flattenSectionSettings = {
    ...__sectionSettings__?.markingSettings,
    ...__sectionSettings__?.settings,
    _id: __sectionSettings__?._id,
    name: __sectionSettings__?.name,
    ...additionalFieldsToAdd
    // timeLimit: sectionSettings?.timeLimit,
    // timerAtQuestion: sectionSettings?.timerAtQuestion
  };

  const sectionSettingsInfo = map(flattenSectionSettings, (settingValue, settingKey) => ({
    [settingKey]: settingItemInfo({ settingValue, settingKey })
  }));

  // Details for a new test
  const newSectionObject = {
    name: '',
    ...additionalFieldsToAdd
  };
  const newSectionSettingsInfo = map(newSectionObject, (settingValue, settingKey) => ({
    [settingKey]: settingItemInfo({ settingValue, settingKey })
  }));

  const testSettingsToMerge = testSettingsInfo.length ? Object.assign(...testSettingsInfo) : {};

  if (newSection) {
    return merge({}, testSettingsToMerge, Object.assign(...newSectionSettingsInfo));
  }

  const mergedSettings = merge({}, testSettingsToMerge, Object.assign(...sectionSettingsInfo));

  return mergedSettings;
}

function formattedForAPI(values) {
  const settingsObject = {};

  if (values._id) {
    settingsObject._id = values._id;
  }

  settingsObject.name = values.name;

  if (values.questionStructure) {
    settingsObject.questionStructure = values.questionStructure;
  }

  if (isNotUndefined(values.resultsDisplayedAt)) {
    settingsObject.resultsDisplayedAt = values.resultsDisplayedAt;
  }

  // @ref: https://stackoverflow.com/a/40560953
  // Update `markingSettings` object
  settingsObject.markingSettings = {
    ...(isNotUndefined(values.markingEnabled) && { markingEnabled: values.markingEnabled }),
    ...(isNotUndefined(values.defaultMarks) && { defaultMarks: values.defaultMarks }),
    ...(isNotUndefined(values.negativeMarkingEnabled) && {
      negativeMarkingEnabled: values.negativeMarkingEnabled
    }),
    ...(isNotUndefined(values.defaultNegativeMarks) && {
      defaultNegativeMarks: values.defaultNegativeMarks
    })
  };

  // Update `settings` object
  settingsObject.settings = {
    // Test settings
    ...(isNotUndefined(values.enableLocking) && { enableLocking: values.enableLocking }),
    ...(isNotUndefined(values.showReportToCandidate) && {
      showReportToCandidate: values.showReportToCandidate
    }),

    // General settings
    ...(isNotUndefined(values.allowSkipQuestions) && {
      allowSkipQuestions: values.allowSkipQuestions
    }),
    ...(isNotUndefined(values.allowAnswerEditing) && {
      allowAnswerEditing: values.allowAnswerEditing
    }),
    ...(isNotUndefined(values.allowBackAndForthNavigation) && {
      allowBackAndForthNavigation: values.allowBackAndForthNavigation
    }),

    // Timer settings
    ...(isNotUndefined(values.enableTimer) && { enableTimer: values.enableTimer }),
    ...(isNotUndefined(values.timeLimit) && { timeLimit: values.timeLimit }),
    ...(isNotUndefined(values.enablePausingTimer) && {
      enablePausingTimer: values.enablePausingTimer
    }),
    ...(isNotUndefined(values.allowedExtraTime) && {
      allowedExtraTime: values.allowedExtraTime
    }),
    ...(isNotUndefined(values.allowedExtraTimeType) && {
      allowedExtraTimeType: values.allowedExtraTimeType
    })
  };

  // Adding `sections: []`

  return settingsObject;
}

// ===========================
function updateDeepSettings(object, keyToFind, timeUnits) {
  const updatedObject = object;

  // forEach(updatedObject, (value, key) => {
  //   if (key === 'settings') {
  //     if (value[keyToFind]) {
  //       convertTimeDuration(value[keyToFind], timeUnits);
  //     }
  //   }

  //   if (key === 'sections' || key === 'subsections') {
  //     forEach(value, (nestedSettingsObj) => {
  //       updateDeepSettings(nestedSettingsObj);
  //     });
  //   }
  // });

  if (updatedObject.sections) {
    updatedObject.sections = forEach(updatedObject.sections, (sectionInfo, sectionKey) => {
      if (sectionInfo.settings) {
        // Breakup conditions for `keyToFind` since sectionInfo.settings may have extra time or subsections, which contain extra time instead
        if (sectionInfo.settings[keyToFind]) {
          // eslint-disable-next-line no-param-reassign
          sectionInfo.settings[keyToFind] = convertTimeDuration(
            sectionInfo.settings[keyToFind],
            timeUnits
          );
        }

        // Update sub sections as well
        if (sectionInfo.subsections) {
          // eslint-disable-next-line no-param-reassign
          sectionInfo.subsections = forEach(
            sectionInfo.subsections,
            (subSectionInfo, subSectionKey) => {
              if (subSectionInfo.settings && subSectionInfo.settings[keyToFind]) {
                // eslint-disable-next-line no-param-reassign
                subSectionInfo.settings[keyToFind] = convertTimeDuration(
                  subSectionInfo.settings[keyToFind],
                  timeUnits
                );
              }
              // Update sub sections as well
              updatedObject.sections[sectionKey].subsections[subSectionKey] = subSectionInfo;
            }
          );
        }
        //
      }
      updatedObject.sections[sectionKey] = sectionInfo;
    });
  }

  return updatedObject;
}

function updateTimersInSettings(settingsObject, timeUnits) {
  let updatedSettings = settingsObject;

  // Update on sections and sub-sections
  updatedSettings = updateDeepSettings(updatedSettings, 'timeLimit', timeUnits);

  // All of this is simply to update allowedExtraTime in sections and sub-sections
  if (updatedSettings.sections) {
    // Simply check for the first section/subsection if allowedExtraTimeType is `seconds`
    // We don't need to check for all since the allowedExtraTimeType is only set at a test level
    if (
      updatedSettings.sections[0]?.settings?.allowedExtraTimeType === EXTRA_TIME_TYPES.mins ||
      (updatedSettings.sections[0]?.subsections &&
        updatedSettings.sections[0]?.subsections[0]?.settings?.allowedExtraTimeType ===
          EXTRA_TIME_TYPES.mins)
    ) {
      // Update allowedExtraTime on sections and sub-sections
      updatedSettings = updateDeepSettings(updatedSettings, 'allowedExtraTime', timeUnits);
    }
  }

  if (settingsObject?.settings?.allowedExtraTime && settingsObject.settings.allowedExtraTime > 0) {
    updatedSettings = merge({}, settingsObject, {
      settings: {
        allowedExtraTime:
          settingsObject.settings.allowedExtraTimeType === EXTRA_TIME_TYPES.mins
            ? convertTimeDuration(settingsObject.settings.allowedExtraTime, timeUnits)
            : settingsObject.settings.allowedExtraTime
      }
    });
  }

  if (settingsObject?.settings?.timeLimit && settingsObject.settings.timeLimit > 0) {
    updatedSettings = merge({}, settingsObject, {
      settings: {
        timeLimit: convertTimeDuration(settingsObject.settings.timeLimit, timeUnits)
      }
    });
  }
  return updatedSettings;
}

export default {
  yupValidations,
  formattedForAPI,
  getSectionSettingsFormFields,
  getTestSettingsFormFields,
  updateTimersInSettings
};
