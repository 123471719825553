import { useState } from 'react';

const useChevron = (initialState = 'chevron-bottom') => {
  const [chevron, toggle] = useState(initialState);

  function toggleChevronDirection() {
    toggle(chevron === 'chevron-bottom' ? 'chevron-top' : 'chevron-bottom');
  }

  return {
    chevron,
    toggleChevronDirection
  };
};

export default useChevron;
