/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';
import hexToRgba from 'hex-to-rgba';

import { colors, mixins } from 'styles/utilities';

export const navList = css`
  list-style: none;
  margin: 0 0 0 20px;
  padding: 0;
`;

export const navListItem = css`
  .nav-link {
    padding: 20px 20px;
    display: block;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${hexToRgba(colors.white, '0.2')};
      opacity: 0;
      ${mixins.transition({ properties: 'opacity' })}
    }
    &:hover,
    &[isactive='true'] {
      &:before {
        opacity: 1;
      }
    }
  }
`;
