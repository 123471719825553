/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/Router';

import { getScrollTop } from 'get-scroll';
import { Container, Row, Col } from 'components/Grid';
import { site } from 'globals/constants';
import BelowNavBreakpoint from 'components/BelowNavBreakpoint';
import H5 from 'components/htmlElements/H5';
import MainNav from './MainNav';
import MainNavMobile from './MainNavMobile';
import NavProfile from './NavProfile';

import * as styles from './Navigation.styles';

const Navigation = (props) => {
  const primaryNav = React.createRef();
  const primaryNavFaux = React.createRef();
  const html = document.documentElement;
  const { body } = document;

  // Adjusting `faux-nav` height
  const [navHeight, setNavHeight] = useState(0);
  function handleSetNavHeight() {
    setNavHeight(`${primaryNav.current?.clientHeight}px`);
  }
  useEffect(() => {
    handleSetNavHeight();
    window.addEventListener('resize', handleSetNavHeight);
    return () => {
      window.removeEventListener('resize', handleSetNavHeight);
    };
  });

  // Toggle nav visibility on scroll
  let lastScrollTop = 0;
  const delta = 50;
  let didScroll = false;
  function handleScroll() {
    didScroll = true;
  }
  function hasScrolled() {
    const st = getScrollTop();
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) {
      return '';
    }

    // If they scrolled down and are past the navbar, add class .nav-up.
    if (st > lastScrollTop && st > primaryNav.current?.clientHeight) {
      // Scroll Down
      primaryNav?.current?.classList.add('nav-up');
      primaryNav?.current?.classList.remove('nav-down');
    } else {
      // Scroll Up
      // eslint-disable-next-line no-lonely-if
      if (st + window.innerHeight < documentHeight) {
        primaryNav?.current?.classList.add('nav-down');
        primaryNav?.current?.classList.remove('nav-up');
      }
    }

    handleSetNavHeight();
    lastScrollTop = st;

    return '';
  }
  function headerInterval() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setInterval(headerInterval, 0);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(headerInterval, 0);
    };
  });

  return (
    <Fragment>
      <div className="faux-nav" ref={primaryNavFaux} style={{ height: navHeight }} />
      <header css={styles.header} ref={primaryNav}>
        <Container>
          <Row>
            <Col>
              <div
                css={styles.navBand}
                className="d-flex justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center nav-band-left">
                  <Link to="/" css={styles.logoLink}>
                    <img
                      src={require('images/logo-emblem-white.svg').default}
                      css={styles.logo}
                      alt={site.name}
                    />
                    <H5 color="white" gtWalsheim bold className="logo-text">
                      {site.name}
                    </H5>
                  </Link>
                  <BelowNavBreakpoint>
                    {(isBelowNavBreakpoint) =>
                      !isBelowNavBreakpoint ? (
                        <MainNav navLinks={props.navLinks} />
                      ) : (
                        <MainNavMobile navLinks={props.navLinks} />
                      )
                    }
                  </BelowNavBreakpoint>
                </div>
                <div className="d-flex align-items-center">
                  <NavProfile />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </Fragment>
  );
};

Navigation.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired
};

Navigation.defaultProps = {};

export default Navigation;
