/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import H2 from 'components/htmlElements/H2';
import Button from 'components/htmlElements/Button';
import { Container, Row, Col } from 'components/Grid';
import { UnstyledLink } from 'components/Router';
import ProductCard from 'components/ProductCard/ProductCard';
import { ROUTE_PATHS } from 'globals/constants';
import PageLoader from 'components/PageLoader';

import { spacer } from 'styles/utilities';
import * as styles from './page.styles';

const HomePage = ({ tests, courses, testsStatus, coursesStatus }) => {
  const [filteredTests, updateTestsArray] = useState(tests);
  const [currentTestsFilter, updateCurrentTestsFilter] = useState();
  useEffect(() => {
    updateTestsArray(tests);
  }, [tests]);

  const handleFilterTests = (filter) => {
    if (filter === 'draft') {
      updateTestsArray(() => tests.filter((test) => test.isDraft));
      updateCurrentTestsFilter(filter);
    } else if (filter === 'published') {
      updateTestsArray(() => tests.filter((test) => !test.isDraft));
      updateCurrentTestsFilter(filter);
    } else {
      updateTestsArray(tests);
      updateCurrentTestsFilter();
    }
  };

  // Filtering for courses
  const [filteredCourses, updateCoursesArray] = useState(courses);
  const [currentCoursesFilter, updateCurrentCoursesFilter] = useState();
  useEffect(() => {
    updateCoursesArray(courses);
  }, [courses]);

  const handleFilterCourses = (filter) => {
    if (filter === 'draft') {
      updateCoursesArray(() => courses.filter((course) => course.isDraft));
      updateCurrentCoursesFilter(filter);
    } else if (filter === 'published') {
      updateCoursesArray(() => courses.filter((course) => !course.isDraft));
      updateCurrentCoursesFilter(filter);
    } else {
      updateCoursesArray(courses);
      updateCurrentCoursesFilter();
    }
  };

  return (
    <section css={spacer.mrBT60}>
      <Container>
        <Row>
          <Col>
            <div className="d-flex justify-content-between align-items-center">
              <H2 workSans semiBold color="blackTransparent50">
                Tests ({tests.length})
              </H2>
              <Button as={UnstyledLink} to={ROUTE_PATHS.testCreate}>
                Add new Test
              </Button>
            </div>
            <div className="d-flex">
              <div css={spacer.mrR15}>
                <Button
                  outline
                  onClick={() => handleFilterTests()}
                  className={!currentTestsFilter ? 'active' : ''}
                >
                  All
                </Button>
              </div>
              <div css={spacer.mrR15}>
                <Button
                  outline
                  onClick={() => handleFilterTests('draft')}
                  className={currentTestsFilter === 'draft' ? 'active' : ''}
                >
                  Draft
                </Button>
              </div>
              <div>
                <Button
                  outline
                  onClick={() => handleFilterTests('published')}
                  className={currentTestsFilter === 'published' ? 'active' : ''}
                >
                  Published
                </Button>
              </div>
            </div>
          </Col>

          {filteredTests &&
            filteredTests.map((test) => (
              <Col className="col-lg-4" css={styles.productCardCol} key={test._id}>
                <ProductCard
                  title={test.name}
                  details={`${test.noOfSections} sections, ${test.noOfSubsections} subsections`}
                  link={`test/${test._id}`}
                  isPublished={!test.isDraft}
                  customColor={test.meta?.color}
                />
              </Col>
            ))}

          <Col>
            {testsStatus !== 'success' ? <PageLoader isFetching isRelative height={300} /> : ''}
          </Col>
        </Row>

        <Row>
          <Col>
            <div
              className="d-flex justify-content-between align-items-center"
              css={[spacer.mrT60, spacer.padT60, styles.coursesSection]}
            >
              <H2 workSans semiBold color="blackTransparent50">
                Courses ({courses.length})
              </H2>
              <Button as={UnstyledLink} to={ROUTE_PATHS.courseCreate}>
                Add new Course
              </Button>
            </div>

            <div className="d-flex">
              <div css={spacer.mrR15}>
                <Button
                  outline
                  onClick={() => handleFilterCourses()}
                  className={!currentCoursesFilter ? 'active' : ''}
                >
                  All
                </Button>
              </div>
              <div css={spacer.mrR15}>
                <Button
                  outline
                  onClick={() => handleFilterCourses('draft')}
                  className={currentCoursesFilter === 'draft' ? 'active' : ''}
                >
                  Draft
                </Button>
              </div>
              <div>
                <Button
                  outline
                  onClick={() => handleFilterCourses('published')}
                  className={currentCoursesFilter === 'published' ? 'active' : ''}
                >
                  Published
                </Button>
              </div>
            </div>
          </Col>

          {filteredCourses &&
            filteredCourses.map((course) => (
              <Col className="col-lg-4" css={styles.productCardCol} key={course._id}>
                <ProductCard
                  title={course.name}
                  details={`${course.noOfSections} sections, ${course.noOfSubsections} subsections`}
                  link={`course/${course._id}`}
                  isPublished={!course.isDraft}
                  customColor={course.meta?.color}
                />
              </Col>
            ))}
          <Col>
            {coursesStatus !== 'success' ? <PageLoader isFetching isRelative height={300} /> : ''}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

HomePage.propTypes = {
  tests: PropTypes.array,
  courses: PropTypes.array,
  testsStatus: PropTypes.string,
  coursesStatus: PropTypes.string
};

HomePage.defaultProps = {
  tests: [],
  courses: [],
  testsStatus: '',
  coursesStatus: ''
};

export default HomePage;
