/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { useParams, Link } from 'components/Router';
import LoaderOverlay from 'components/LoaderOverlay';
import { useReorderQuestion } from 'api/questions';

// Components
import Icon from 'components/Icon';

import QuestionsContainer from 'hooks/useCreateQuestion';
import useSearchQuery from 'hooks/useSearchQuery';

// Styling
import { colors } from 'styles/utilities';
import * as styles from './QuestionBand.styles';

const AddNewQuestionButton = ({ handleNewQuestionOnClick }) => (
  <div
    css={styles.questionBand}
    onClick={handleNewQuestionOnClick}
    onKeyDown={() => {}}
    role="button"
    tabIndex={0}
  >
    <Icon icon="add" size="20" color={colors.lighterBlue} />
  </div>
);

AddNewQuestionButton.propTypes = {
  handleNewQuestionOnClick: PropTypes.func.isRequired
};

const QuestionButton = ({ id, usage, finalQuestionTypeNumber }) => {
  const { useParseQueryString, setQueryParams } = useSearchQuery();
  const routeParams = useParams();
  const queryStringData = useParseQueryString();

  const [isActive, toggleIsActive] = useState(queryStringData.questionId === id);
  useEffect(() => {
    toggleIsActive(queryStringData.questionId === id);
  }, [id, queryStringData.questionId]);

  const handleOnClick = () => {
    const queryParams = {
      ...queryStringData,
      questionId: id
    };

    // Setting full path because the component is used on test overview page as well
    return `/test/${routeParams.testId}/questions?${setQueryParams(queryParams)}`;
  };

  let text = usage?.charAt(0).toUpperCase() || '!';
  if (usage === 'final') {
    text = `Q${finalQuestionTypeNumber}`;
  }

  return (
    <Link
      to={handleOnClick()}
      large
      workSans
      semiBold
      css={styles.questionBand({ active: isActive })}
    >
      {text}
    </Link>
  );
};
QuestionButton.propTypes = {
  finalQuestionTypeNumber: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  usage: PropTypes.string
};
QuestionButton.defaultProps = {
  usage: undefined
};

const SortableItem = SortableElement(({ questionNumber, question, finalQuestionTypeNumber }) => (
  <QuestionButton
    questionNumber={parseInt(questionNumber) + 1}
    id={question._id}
    usage={question.usage}
    finalQuestionTypeNumber={finalQuestionTypeNumber}
  />
));

const SortableList = SortableContainer(({ questions, handleNewQuestionOnClick }) => {
  let finalQuestionCount = 0;
  return (
    <Fragment>
      <div className="d-flex flex-wrap">
        {questions.map((question, index) => {
          if (question.usage === 'final') {
            finalQuestionCount += 1;
          }

          return (
            <SortableItem
              index={index}
              key={question._id}
              question={question}
              questionNumber={index}
              finalQuestionTypeNumber={finalQuestionCount}
            />
          );
        })}
        <AddNewQuestionButton handleNewQuestionOnClick={handleNewQuestionOnClick} />
      </div>
    </Fragment>
  );
});

const QuestionBand = ({ handleNewQuestionOnClick }) => {
  const { questions } = QuestionsContainer.useContainer();
  const [questionsList, updateQuestionsList] = useState(questions);

  const { reorderQuestion } = useReorderQuestion();

  useEffect(() => {
    updateQuestionsList(questions);
  }, [questions]);

  const [overlay, toggleOverlay] = useState(false);

  const updateQuestionsOrder = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return true;
    }

    const updatedQuestionsList = arrayMove(questionsList, oldIndex, newIndex);

    // Optimistic update
    updateQuestionsList(updatedQuestionsList);

    toggleOverlay(true);

    // Call API here
    await reorderQuestion({
      questionId: updatedQuestionsList[newIndex]._id,
      updatedQuestionsOrder: updatedQuestionsList.map((item) => item._id)
    });

    toggleOverlay(false);

    return true;
  };

  return (
    <div className="is-relative">
      <SortableList
        useWindowAsScrollContainer
        axis="xy"
        distance={1}
        questions={questionsList}
        onSortEnd={updateQuestionsOrder}
        handleNewQuestionOnClick={handleNewQuestionOnClick}
      />

      {overlay && <LoaderOverlay />}
    </div>
  );
};

QuestionBand.propTypes = {
  handleNewQuestionOnClick: PropTypes.func.isRequired
};

export default QuestionBand;
