import TextQuestionTemplate from './TextQuestion/TextQuestion';
import TwoColumnComprehensionTemplate from './TwoColumnComprehension/TwoColumnComprehension';
import AnagramQuestionTemplate from './AnagramQuestion/AnagramQuestion';
import ShuffledSentencesQuestionTemplate from './ShuffledSentencesQuestion/ShuffledSentencesQuestion';
import SpellingPunctuationQuestionTemplate from './SpellingPunctuationQuestion/SpellingPunctuationQuestion';
import MathEquationAnagramsQuestionTemplate from './MathEquationAnagramsQuestion/MathEquationAnagramsQuestion';
import ClozeQuestionTemplate from './ClozeQuestion/ClozeQuestion';
import PictureImageQuestionTemplate from './PictureImageQuestion/PictureImageQuestion';
import SelectedLettersTemplate from './SelectedLetters/SelectedLetters';

// Constant for questions to use across the app
const QUESTION_TYPE = [
  { type: 'text', niceName: 'Text Based' },
  { type: 'image', niceName: 'Image Based' },
  { type: 'summary', niceName: 'Summary Based' },
  { type: 'twoColComprehension', niceName: '2 Column Comprehension' },
  { type: 'shuffledSentences', niceName: 'Shuffled Sentence' },
  { type: 'sentenceCompletion', niceName: 'Sentence Completion' },
  { type: 'spellingPunctuation', niceName: 'Spelling & Punctuation' },
  { type: 'anagrams', niceName: 'Anagrams' },
  { type: 'anagramsMathEq', niceName: 'Math Equation Anagrams' },
  { type: 'cloze', niceName: 'Cloze' },
  { type: 'pictureImage', niceName: 'Picture Question' },
  { type: 'selectedLetters', niceName: 'Selected Letters' }
];

export {
  QUESTION_TYPE,
  TextQuestionTemplate,
  TwoColumnComprehensionTemplate,
  AnagramQuestionTemplate,
  ShuffledSentencesQuestionTemplate,
  SpellingPunctuationQuestionTemplate,
  MathEquationAnagramsQuestionTemplate,
  ClozeQuestionTemplate,
  PictureImageQuestionTemplate,
  SelectedLettersTemplate
};
