import React from 'react';
import PropTypes from 'prop-types';

import { ROUTE_PATHS, TEST_STRUCTURE } from 'globals/constants';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';

import CreateTestContainer from 'hooks/useCreateTest';
import QuestionsContainer from 'hooks/useCreateQuestion';
import useSearchQuery from 'hooks/useSearchQuery';

import { useGetTestSettings } from 'api/test';
import { useGetQuestions } from 'api/questions';

import CreateQuestion from './page';

export const useGetCurrentTestLevel = (testSettings) => {
  const { getSearchQuery } = useSearchQuery();

  const getSubSectionId = () => {
    const idInURL = getSearchQuery('subsectionId');
    if (idInURL) return idInURL;

    // If it doesn't exist in URL, return the first subsection ID
    return testSettings.sections[0].subsections[0]._id;
  };

  const getSectionId = () => {
    const idInURL = getSearchQuery('sectionId');
    if (idInURL) return idInURL;

    // If it doesn't exist in URL, return the first section ID
    return testSettings.sections[0]._id;
  };

  if (testSettings.questionStructure === TEST_STRUCTURE.subsections) {
    return {
      levelId: getSubSectionId(),
      level: TEST_STRUCTURE.subsections
    };
  }
  if (testSettings.questionStructure === TEST_STRUCTURE.sections) {
    return {
      levelId: getSectionId(),
      level: TEST_STRUCTURE.sections
    };
  }
  return {
    levelId: testSettings._id,
    level: TEST_STRUCTURE.test
  };
};

const CreateQuestionContainer = () => {
  const { testSettings } = CreateTestContainer.useContainer();

  // Get info on the test structure to fetch questions accordingly
  const { levelId, level } = useGetCurrentTestLevel(testSettings);

  const { data: questions, error: questionsError, status: questionsStatus } = useGetQuestions(
    levelId,
    level
  );

  if (questionsError || (questions && questions.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (questionsStatus !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (questions) {
    return (
      <QuestionsContainer.Provider initialState={questions.data}>
        <CreateQuestion />
      </QuestionsContainer.Provider>
    );
  }

  return null;
};

const WithTestSettingsProvider = ({ testId }) => {
  const { data: test, error: testError, status: testStatus } = useGetTestSettings(testId);

  if (testError || (test && test.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (testStatus !== 'success') {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (test) {
    return (
      <CreateTestContainer.Provider initialState={test.data}>
        <CreateQuestionContainer />
      </CreateTestContainer.Provider>
    );
  }

  return null;
};

WithTestSettingsProvider.propTypes = {
  testId: PropTypes.string.isRequired
};

export default WithTestSettingsProvider;
