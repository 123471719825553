/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { misc } from 'styles/utilities';

export const productCardCol = css`
  display: grid;
  grid-auto-rows: 1fr;
`;

export const coursesSection = css`
  border-top: ${misc.border.grey};
`;
