/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

const sidebarWidth = '300px';

export const wrapper = css`
  width: ${sidebarWidth};
  border-right: 1px solid ${colors.blackTransparent10};
  background-color: ${colors.white};
  flex-shrink: 0;
`;

export const listItem = (props) => css`
  width: 100%;
  border-bottom: 1px solid ${colors.blackTransparent10};
  background-color: ${props.isActive ? colors.secondaryLinkActive : colors.white};
  .list-wrapper-inner & {
    border-bottom: none;
  }
  padding: 15px 0 0 15px;
`;

export const listItemContent = css`
  border-bottom: 1px solid ${colors.blackTransparent10};
  padding: 0 0 15px 0;
  width: 100%;
`;
