/** @jsxImportSource @emotion/react */
import React, { Fragment, useState, useEffect } from 'react';

import { useParams, navigate } from 'components/Router';

import { Container } from 'components/Grid';
import LoaderOverlay from 'components/LoaderOverlay';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import CourseSectionSummaryBand from 'components/CourseSectionSummaryBand';
import LessonsBand from 'components/LessonsBand';

import CourseContainer from 'hooks/useCreateCourse';
import LessonsContainer from 'hooks/useCourseLessons';
import useSearchQuery from 'hooks/useSearchQuery';
import { COURSE_STRUCTURE } from 'globals/constants';
import { useSectionSettingsContext } from 'components/CourseSectionSettingsForm/CourseSectionSettingsContext';
import { useSubSectionSettingsContext } from 'components/CourseSubSectionSettingsForm/CourseSubSectionSettingsContext';

// Styling
import { bgColor, spacer } from 'styles/utilities';

const LesssonsSectionHeader = () => {
  const routeParams = useParams();
  const { lessons, createNewLesson } = LessonsContainer.useContainer();

  const { courseSettings, fetchSectionData, fetchSubsectionData } = CourseContainer.useContainer();
  const {
    editSectionSettings,
    toggleHideDeleteOption: sectionHideDelete
  } = useSectionSettingsContext();
  const {
    editSubSectionSettings,
    toggleHideDeleteOption: subSectionHideDelete
  } = useSubSectionSettingsContext();

  const { useParseQueryString, setQueryParams } = useSearchQuery();
  const queryStringData = useParseQueryString();

  const [isSection, toggleIsSection] = useState(
    COURSE_STRUCTURE.courseStructure === COURSE_STRUCTURE.sections
  );
  const [isSubSection, toggleIsSubSection] = useState(
    COURSE_STRUCTURE.courseStructure === COURSE_STRUCTURE.subsections
  );
  useEffect(() => {
    toggleIsSection(courseSettings.courseStructure === COURSE_STRUCTURE.sections);
    toggleIsSubSection(courseSettings.courseStructure === COURSE_STRUCTURE.subsections);
  }, [courseSettings.courseStructure]);

  // Update settings to display based on course structure
  const [sectionSummaryProps, updateSectionSummaryProps] = useState({ data: courseSettings });
  useEffect(() => {
    updateSectionSummaryProps(() => {
      if (isSection || isSubSection) {
        const sectionIdWithFallback = queryStringData.sectionId || courseSettings.sections[0]._id;

        const sectionData = fetchSectionData(courseSettings, sectionIdWithFallback);
        // Returning sub-section data
        if (isSubSection) {
          const subSectionIdWithFallback =
            queryStringData.subsectionId || courseSettings.sections[0].subsections[0]._id;
          return {
            data: fetchSubsectionData(sectionData, subSectionIdWithFallback),
            isSubSection: true,
            parentSectionId: sectionIdWithFallback,
            parentSectionName: sectionData?.name,
            hideAddQuestions: true,
            borderless: true,
            onHandleClick: () => {
              // // Hide delete button.
              // // An edge case where deleting the last section/subsection will throw and error on questions page
              subSectionHideDelete(true);
              editSubSectionSettings(sectionIdWithFallback, subSectionIdWithFallback);
            }
          };
        }

        // Returning section data
        return {
          data: sectionData,
          hideAddQuestions: true,
          borderless: true,
          onHandleClick: () => {
            // Hide delete button.
            // An edge case where deleting the last section/subsection will throw and error on questions page
            sectionHideDelete(true);

            editSectionSettings(queryStringData.sectionId);
          }
        };
      }

      // Returning test data
      return {
        data: courseSettings,
        hideAddQuestions: true,
        borderless: true,
        onHandleClick: () => {
          navigate(`/course/${routeParams.courseId}/settings`);
        }
      };
    });
  }, [
    courseSettings,
    editSectionSettings,
    fetchSectionData,
    fetchSubsectionData,
    isSection,
    isSubSection,
    queryStringData.sectionId,
    queryStringData.subsectionId,
    routeParams.courseId,
    sectionHideDelete,
    editSubSectionSettings,
    subSectionHideDelete
  ]);

  // Toggle overlay
  const [overlay, toggleOverlay] = useState(false);

  const handleNewQuestionOnClick = async () => {
    let body = {
      courseId: courseSettings._id
    };

    const queryParams = {};

    if (isSection) {
      body = {
        ...body,
        atLevelSectionId: queryStringData.sectionId
      };
      queryParams.sectionId = queryStringData.sectionId;
    } else if (isSubSection) {
      body = {
        ...body,
        atLevelSubsectionId: queryStringData.subsectionId
      };
      queryParams.sectionId = queryStringData.sectionId;
      queryParams.subsectionId = queryStringData.subsectionId;
    } else {
      body = {
        ...body,
        atLevelCourseId: courseSettings._id
      };
    }

    toggleOverlay(true);
    const data = await createNewLesson(body);
    toggleOverlay(false);

    if (data) {
      queryParams.lessonId = data._id;

      navigate(`?${setQueryParams(queryParams)}`);
    }
  };

  // Go to last question in the selected list of questions
  if (!queryStringData.lessonId && lessons.length) {
    navigate(
      `?${setQueryParams({ ...queryStringData, lessonId: lessons[lessons.length - 1]._id })}`,
      { replace: true }
    );
  }

  const getCrumbs = () => {
    let crumbArray = [{ name: courseSettings.name, link: `/course/${courseSettings._id}` }];
    if (isSection || isSubSection) {
      if (isSubSection) {
        crumbArray = [...crumbArray, { name: sectionSummaryProps.parentSectionName }];
      }
      crumbArray = [...crumbArray, { name: sectionSummaryProps.data.name }];
    }
    return [...crumbArray, { name: 'Lessons' }];
  };

  return (
    <Fragment>
      <div css={[bgColor.white]} className="is-relative">
        <Container css={[bgColor.white, spacer.padB20]}>
          <div css={spacer.padT30}>
            <Breadcrumb routes={getCrumbs()} />
          </div>
          <CourseSectionSummaryBand {...sectionSummaryProps} />

          <LessonsBand handleNewQuestionOnClick={handleNewQuestionOnClick} />
        </Container>
        {overlay && <LoaderOverlay />}
      </div>
    </Fragment>
  );
};

export default LesssonsSectionHeader;
