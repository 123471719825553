/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors } from 'styles/utilities';

export const dropdownWrapper = css`
  margin: 0 20px;
  width: 100%;
`;

export const activeItem = css`
  padding: 12px 20px;

  border-left: 1px solid ${colors.white};
  border-right: 1px solid ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .icon {
    margin-left: 10px;
  }
`;

export const mainNavDropdown = css`
  position: relative;
  .main-nav-dropdown-content {
    ${'' /* width: 100%; */}
  }
`;
