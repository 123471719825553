import React, { useContext, createContext, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'components/Modal';
import { useGetTests } from 'api/tests';

const VersionModalContext = createContext();

export const useVersionModalContext = () => useContext(VersionModalContext);

const VersionModalProvider = ({ children, testGroupId, testName }) => {
  const { isOpen, openModal, closeModal } = useModal(false);
  const [versionsList, updateVersionsList] = useState([]);

  const { data: tests, isLoading, isSuccess } = useGetTests({
    testGroupId,
    getAllVersions: true,
    tupleKey: testGroupId
  });

  useEffect(() => {
    if (isSuccess) {
      updateVersionsList(tests.data);
    }
  }, [isLoading, isSuccess, tests.data]);

  const value = useMemo(
    () => ({ openModal, closeModal, isOpen, versionsList, testsLoading: isLoading, testName }),
    [openModal, closeModal, isOpen, versionsList, isLoading, testName]
  );

  return <VersionModalContext.Provider value={value}>{children}</VersionModalContext.Provider>;
};

VersionModalProvider.propTypes = {
  children: PropTypes.any.isRequired,
  testGroupId: PropTypes.string.isRequired,
  testName: PropTypes.string.isRequired
};

export default VersionModalProvider;
