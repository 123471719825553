/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './SidebarShell.styles';

const SidebarShell = ({ children }) => <div css={styles.wrapper}>{children}</div>;

SidebarShell.propTypes = {
  children: PropTypes.node.isRequired
};

export default SidebarShell;
