/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

export const name = css`
  margin-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;
`;

export const navProfileDropdown = css`
  position: relative;
  .nav-profile-dropdown-trigger {
    cursor: pointer;
  }
  .nav-profile-dropdown-contents {
    right: 0;
  }
`;
