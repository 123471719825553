/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import A from 'components/htmlElements/A';
import H2 from 'components/htmlElements/H2';
import H4 from 'components/htmlElements/H4';
import H5 from 'components/htmlElements/H5';
import P from 'components/htmlElements/P';
import { navigate } from 'components/Router';
import Button from 'components/htmlElements/Button';
import {
  Input,
  Textarea,
  FieldWrapper,
  FieldError,
  FieldLabel
} from 'components/htmlElements/Fields';
import RadioButton from 'components/RadioButton';
import ToggleSwitch from 'components/ToggleSwitch';
import InlineToast from 'components/InlineToast';
import { Container, Col, Row } from 'components/Grid';
import InputSuffix from 'components/InputSuffix';
import VisualizerCard from 'components/VisualizerCard';
// import DescriptiveCheckBox from 'components/DescriptiveCheckBox/';
// import * as descriptiveCheckbox from 'components/DescriptiveCheckBox/variants/variants';

import * as visualizerCard from 'components/VisualizerCard/variants/variants';
import { TEST_STRUCTURE, EXTRA_TIME_TYPES } from 'globals/constants';
import settingsHelpers from 'utils/settingsHelpers';

import CreateTestContainer from 'hooks/useCreateTest';

import { spacer, bgColor } from 'styles/utilities';
import * as styles from './page.styles';

const { yupValidations } = settingsHelpers;

const TestSchema = Yup.object().shape({
  name: Yup.string().required(yupValidations.defaultRequired),
  defaultMarks: Yup.number().required(yupValidations.defaultMarks),
  defaultNegativeMarks: Yup.number().required(yupValidations.defaultNegativeMarks),
  questionStructure: Yup.string().required(yupValidations.questionStructure),
  enableTimer: Yup.boolean()
  // allowedExtraTime: Yup.number().when(['enableTimer', 'questionStructure'], {
  //   is: (enableTimer, questionStructure) => enableTimer === true && questionStructure === 'test',
  //   then: Yup.number().required(yupValidations.timeLimit),
  //   otherwise: Yup.number()
  // })
});

const TestSettings = () => {
  const createTestContainer = CreateTestContainer.useContainer();
  const { testSettings } = createTestContainer;
  const { _id: testId } = testSettings;

  const [editTest, setEditTest] = useState(false);
  const [submitDraft, setSubmitDraft] = useState(false);
  const [isInDraftMode, updateIsInDraftMode] = useState(testSettings.isDraft);

  useEffect(() => {
    if (testId) {
      setEditTest(true);
      updateIsInDraftMode(testSettings.isDraft);
    }
  }, [testId, testSettings.isDraft]);

  const [enableExtraTime, toggleEnableExtraTime] = useState(
    !!testSettings.settings.allowedExtraTime
  );

  const saveAndPublish = async (values) => {
    if (editTest) {
      let changedSettings = createTestContainer.normalizeTestSettings(values, testSettings);
      changedSettings = { ...changedSettings, isDraft: false };
      const data = await createTestContainer.updateCurrentTest(testId, changedSettings);
      if (data) navigate(`/test/${data._id}`, { replace: true });
    } else {
      let settings = createTestContainer.normalizeTestSettings(values);
      settings = { ...settings, isDraft: false };
      const data = await createTestContainer.createNewTest(settings);
      if (data) navigate(`/test/${data._id}`, { replace: true });
    }
  };

  const saveAsDraft = async (values) => {
    if (editTest) {
      let changedSettings = createTestContainer.normalizeTestSettings(values, testSettings);
      changedSettings = { ...changedSettings, isDraft: true };
      const data = await createTestContainer.updateCurrentTest(testId, changedSettings);
      setSubmitDraft(false);
      if (data) navigate(`/test/${data._id}`, { replace: true });
    } else {
      let settings = createTestContainer.normalizeTestSettings(values);
      settings = { ...settings, isDraft: true };
      const data = await createTestContainer.createNewTest(settings);
      setSubmitDraft(false);
      if (data) navigate(`/test/${data._id}`, { replace: true });
    }
  };

  const setExtraTimerOptions = (values) => {
    const updatedValues = values;
    if (!enableExtraTime || !updatedValues.enableTimer) {
      delete updatedValues.allowedExtraTime;
      delete updatedValues.allowedExtraTimeType;
    }
    return updatedValues;
  };

  const handleSubmit = async (values, actions) => {
    actions.setValues(setExtraTimerOptions(values));

    actions.setSubmitting(true);
    if (submitDraft) {
      await saveAsDraft(values);
    } else {
      await saveAndPublish(values);
    }
    actions.setSubmitting(false);
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          name: testSettings.name,

          // Marking Settings
          markingEnabled: testSettings.markingSettings.markingEnabled,
          defaultMarks: testSettings.markingSettings.defaultMarks,
          negativeMarkingEnabled: testSettings.markingSettings.negativeMarkingEnabled,
          defaultNegativeMarks: testSettings.markingSettings.defaultNegativeMarks,

          // Test settings - These don't flow into sections/subSections
          questionStructure: testSettings.questionStructure,
          enableLocking: testSettings.settings.enableLocking,
          resultsDisplayedAt: testSettings.resultsDisplayedAt,

          // General Settings
          allowSkipQuestions: testSettings.settings.allowSkipQuestions,
          allowAnswerEditing: testSettings.settings.allowAnswerEditing,
          allowBackAndForthNavigation: testSettings.settings.allowBackAndForthNavigation,

          // Time and score settings
          enableTimer: testSettings.settings.enableTimer,
          // timeLimit: testSettings.timeLimit, // The value depends on questionStructure
          enablePausingTimer: testSettings.settings.enablePausingTimer,
          allowedExtraTime: testSettings.settings.allowedExtraTime,
          allowedExtraTimeType: testSettings.settings.allowedExtraTimeType,

          // Metadata
          description: testSettings.meta && testSettings.meta.description,
          color: testSettings.meta && testSettings.meta.color
        }}
        validationSchema={TestSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, isSubmitting, submitForm }) => (
          <Form>
            <div css={bgColor.mediumGrey}>
              <Container css={spacer.mrB80}>
                <Row className="d-flex justify-content-center">
                  <Col className="col-lg-6">
                    {/* Create New Test Header */}
                    <H2 workSans semiBold css={[spacer.padT60, spacer.mrB40]}>
                      {editTest ? 'Edit' : 'Create New'} Test
                    </H2>

                    {/* Test Intro Form */}

                    {/* Test name */}
                    <FieldWrapper>
                      <FieldLabel className="placeholder" htmlFor="name" required>
                        Test name
                      </FieldLabel>
                      <Field type="text" name="name" id="name" as={Input} placeholder="Test name" />
                      {errors.name && touched.name && <FieldError>{errors.name}</FieldError>}
                    </FieldWrapper>

                    {/* Test meta data */}
                    <FieldWrapper>
                      <FieldLabel className="placeholder">Description (Optional)</FieldLabel>
                      <Field
                        type="text"
                        name="description"
                        value={values.description}
                        as={Textarea}
                        placeholder="Enter short description for this test"
                      />
                    </FieldWrapper>

                    <FieldWrapper>
                      <FieldLabel className="placeholder">Color (Optional)</FieldLabel>
                      <br />
                      <Field type="color" name="color" />
                      <H5 workSans>{values.color}</H5>
                    </FieldWrapper>
                  </Col>

                  {/* Divider */}
                  <Col>
                    <div css={[styles.sectionDivider, spacer.mrT40, spacer.mrB60]} />
                  </Col>
                  {/* Divider */}

                  {/* Basic settings */}
                  <Col className="col-lg-6">
                    <H4 workSans css={spacer.mrB30}>
                      Basic settings
                    </H4>

                    {/* Default marks toggle */}
                    <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                      <Col className="col-lg-9">
                        <H5 workSans>Set default marks per question</H5>
                      </Col>
                      <Col className="col-lg-3">
                        <label className="w-100" htmlFor="markingEnabled">
                          <Field
                            id="markingEnabled"
                            css={styles.label}
                            type="checkbox"
                            name="markingEnabled"
                            value={values.markingEnabled}
                            checked={values.markingEnabled}
                          />
                          <ToggleSwitch state={values.markingEnabled} />
                        </label>
                      </Col>
                    </Row>

                    {/* Default marks input */}
                    {values.markingEnabled && (
                      <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                        <Col className="col-lg-9">
                          <H5 workSans>Set default marks per question</H5>
                          <P workSans small color="blackTransparent50">
                            You can still override marks for individual questions
                          </P>
                        </Col>
                        <Col className="col-lg-3">
                          <FieldWrapper>
                            <Field
                              className="text-center"
                              type="number"
                              name="defaultMarks"
                              as={Input}
                              value={values.defaultMarks}
                            />
                          </FieldWrapper>
                          {values.markingEnabled && errors.defaultMarks && touched.defaultMarks && (
                            <FieldError>{errors.defaultMarks}</FieldError>
                          )}
                        </Col>
                      </Row>
                    )}

                    {/* Default negative marks toggle */}
                    <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                      <Col className="col-lg-9">
                        <H5 workSans>Set default negative marks per question</H5>
                      </Col>
                      <Col className="col-lg-3">
                        <label className="w-100" htmlFor="negativeMarkingEnabled">
                          <Field
                            id="negativeMarkingEnabled"
                            css={styles.label}
                            type="checkbox"
                            name="negativeMarkingEnabled"
                            value={values.negativeMarkingEnabled}
                            checked={values.negativeMarkingEnabled}
                          />
                          <ToggleSwitch state={values.negativeMarkingEnabled} />
                        </label>
                      </Col>
                    </Row>

                    {/* Default negative marks input */}
                    {values.negativeMarkingEnabled ? (
                      <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                        <Col className="col-lg-9">
                          <H5 workSans>Set default negative marks per question</H5>
                          <P workSans small color="blackTransparent50">
                            You can still override negative marks for individual questions
                          </P>
                        </Col>
                        <Col className="col-lg-3">
                          <FieldWrapper>
                            <Field
                              className="text-center"
                              type="number"
                              name="defaultNegativeMarks"
                              as={Input}
                              value={values.defaultNegativeMarks}
                            />
                          </FieldWrapper>
                          {values.negativeMarkingEnabled &&
                            errors.defaultNegativeMarks &&
                            touched.defaultNegativeMarks && (
                              <FieldError>{errors.defaultNegativeMarks}</FieldError>
                            )}
                        </Col>
                      </Row>
                    ) : null}

                    {/* Allow skipping questions */}
                    <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                      <Col className="col-lg-9">
                        <H5 workSans>Allow skipping questions?</H5>
                        <P workSans small color="blackTransparent50">
                          This will let the candidate submit a test without having answered all
                          questions
                        </P>
                      </Col>
                      <Col className="col-lg-3">
                        <label className="w-100" htmlFor="allowSkipQuestions">
                          <Field
                            id="allowSkipQuestions"
                            css={styles.label}
                            type="checkbox"
                            name="allowSkipQuestions"
                            value={values.allowSkipQuestions}
                            checked={values.allowSkipQuestions}
                          />
                          <ToggleSwitch state={values.allowSkipQuestions} />
                        </label>
                      </Col>

                      {values.negativeMarkingEnabled ? (
                        <Col className="col-lg-12">
                          <InlineToast
                            type="notice"
                            message="Since you’ve enabed negative marking, we recommend allowing skipping questions"
                          />
                        </Col>
                      ) : null}
                    </Row>

                    {/* Enable Editing Answers */}
                    <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                      <Col className="col-lg-9">
                        <H5 workSans>Enable Editing Answers? (Before submission)</H5>
                        <P workSans small color="blackTransparent50">
                          This will let candidates change their answer any time before they submit a
                          module
                        </P>
                      </Col>
                      <Col className="col-lg-3">
                        <label className="w-100" htmlFor="allowAnswerEditing">
                          <Field
                            id="allowAnswerEditing"
                            css={styles.label}
                            type="checkbox"
                            name="allowAnswerEditing"
                            value={values.allowAnswerEditing}
                            checked={values.allowAnswerEditing}
                          />
                          <ToggleSwitch state={values.allowAnswerEditing} />
                        </label>
                      </Col>
                    </Row>

                    {/* Allow back/forth navigation between questions */}
                    <Row
                      css={[styles.settings, spacer.padB30, spacer.padT40]}
                      className="last-child"
                    >
                      <Col className="col-lg-9">
                        <H5 workSans>Allow back/forth navigation between questions?</H5>
                        <P workSans small color="blackTransparent50">
                          Sets whether the candidate can only browse through questions linearly, or
                          in any order.
                        </P>
                      </Col>
                      <Col className="col-lg-3">
                        <label className="w-100" htmlFor="allowBackAndForthNavigation">
                          <Field
                            id="allowBackAndForthNavigation"
                            css={styles.label}
                            type="checkbox"
                            name="allowBackAndForthNavigation"
                            value={values.allowBackAndForthNavigation}
                            checked={values.allowBackAndForthNavigation}
                          />
                          <ToggleSwitch state={values.allowBackAndForthNavigation} />
                        </label>
                      </Col>
                    </Row>
                  </Col>

                  {/* Divider */}
                  <Col>
                    <div css={[styles.sectionDivider, spacer.mrT40, spacer.mrB60]} />
                  </Col>

                  <Col className="col-lg-6">
                    {/* Section settings */}
                    <H4 workSans css={spacer.mrB30}>
                      Section settings
                    </H4>

                    {/* Test section structure description */}
                    <Row css={[styles.settings, spacer.padB30]} className="last-child">
                      <Col className="col-lg-10">
                        <H5 workSans>Select test section structure</H5>
                        <InlineToast
                          type="error"
                          message="You cannot change this after you’ve started adding questions, so choose carefully!"
                        />
                      </Col>
                    </Row>
                  </Col>

                  {/* Section Visualizer Card */}
                  <Col className="col-lg-10">
                    <Row css={[styles.settings, spacer.padB30]} className="no-border">
                      <Col className="col-lg-4">
                        <label className="h-100" htmlFor="questionStructure-test">
                          <Field
                            id="questionStructure-test"
                            type="radio"
                            css={styles.label}
                            name="questionStructure"
                            value={TEST_STRUCTURE.test}
                            disabled={editTest}
                          />
                          <VisualizerCard
                            variant={visualizerCard.NO_SECTIONS}
                            selected={values.questionStructure === TEST_STRUCTURE.test}
                          />
                        </label>
                      </Col>
                      <Col className="col-lg-4">
                        <label className="h-100" htmlFor="questionStructure-sections">
                          <Field
                            id="questionStructure-sections"
                            type="radio"
                            css={styles.label}
                            name="questionStructure"
                            value={TEST_STRUCTURE.sections}
                            disabled={editTest}
                          />
                          <VisualizerCard
                            variant={visualizerCard.SINGLE_SECTIONS}
                            selected={values.questionStructure === TEST_STRUCTURE.sections}
                          />
                        </label>
                      </Col>
                      <Col className="col-lg-4">
                        <label className="h-100" htmlFor="questionStructure-subsections">
                          <Field
                            id="questionStructure-subsections"
                            type="radio"
                            css={styles.label}
                            name="questionStructure"
                            value={TEST_STRUCTURE.subsections}
                            disabled={editTest}
                          />
                          <VisualizerCard
                            variant={visualizerCard.SECTIONS_AND_SUBSECTIONS}
                            selected={values.questionStructure === TEST_STRUCTURE.subsections}
                          />
                        </label>
                      </Col>
                      {errors.questionStructure && touched.questionStructure && (
                        <FieldError>{errors.questionStructure}</FieldError>
                      )}
                    </Row>
                  </Col>

                  <Col className="col-lg-6">
                    {/* Enable timer */}
                    <Row
                      css={[styles.settings, spacer.padB30, spacer.padT40, spacer.mrT40]}
                      className="border-top"
                    >
                      <Col className="col-lg-9">
                        <H5 workSans>Enable Timer?</H5>
                      </Col>
                      <Col className="col-lg-3">
                        <label className="w-100" htmlFor="enableTimer">
                          <Field
                            id="enableTimer"
                            css={styles.label}
                            type="checkbox"
                            name="enableTimer"
                            value={values.enableTimer}
                            checked={values.enableTimer}
                          />
                          <ToggleSwitch state={values.enableTimer} />
                        </label>
                      </Col>
                    </Row>

                    {/* If test structure is of test level, diplay time limit input */}
                    {values.questionStructure === TEST_STRUCTURE.test && values.enableTimer && (
                      <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                        <Col className="col-lg-9">
                          <H5 workSans>Test duration</H5>
                        </Col>
                        <Col className="col-lg-3">
                          <Field
                            type="number"
                            step=".01"
                            name="timeLimit"
                            as={InputSuffix}
                            value={values.timeLimit || ''}
                            suffix="mins"
                            className="text-center"
                          />
                        </Col>
                        {errors.timeLimit && touched.timeLimit && (
                          <FieldError>{errors.timeLimit}</FieldError>
                        )}
                      </Row>
                    )}

                    {/* Allow pausing timer */}
                    {values.enableTimer ? (
                      <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                        <Col className="col-lg-9">
                          <H5 workSans>Allow pausing timer?</H5>
                          <P workSans small color="blackTransparent50">
                            When a candidate pauses, the screen goes blank and they can’t take any
                            action till they un-pause. Can pause any number of times, for any
                            duration.
                          </P>
                        </Col>
                        <Col className="col-lg-3">
                          <label className="w-100" htmlFor="enablePausingTimer">
                            <Field
                              id="enablePausingTimer"
                              css={styles.label}
                              type="checkbox"
                              name="enablePausingTimer"
                              value={values.enablePausingTimer}
                              checked={values.enablePausingTimer}
                            />
                            <ToggleSwitch state={values.enablePausingTimer} />
                          </label>
                        </Col>
                      </Row>
                    ) : null}

                    {/* Enable extra time */}
                    {values.enableTimer && (
                      <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                        <Col className="col-lg-9">
                          <H5 workSans>Enable extra time?</H5>
                        </Col>
                        <Col className="col-lg-3">
                          <ToggleSwitch
                            state={enableExtraTime || values.allowedExtraTime > 0}
                            onClick={() => toggleEnableExtraTime((s) => !s)}
                          />
                        </Col>
                      </Row>
                    )}

                    {/* If extra time is enabled, extra time input */}
                    {(values.enableTimer && enableExtraTime) ||
                    (values.allowedExtraTime > 0 && enableExtraTime) ? (
                      <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                        <Col className="col-lg-9">
                          <H5 workSans>Extra time</H5>
                          <div className="d-flex" css={spacer.mrT10}>
                            <div css={spacer.mrR15}>
                              <Field
                                as={RadioButton}
                                id="allowedExtraTimeType-mins"
                                label="Minutes"
                                type="radio"
                                name="allowedExtraTimeType"
                                value={EXTRA_TIME_TYPES.mins}
                              />
                            </div>
                            <Field
                              as={RadioButton}
                              id="allowedExtraTimeType-percent"
                              label="Percentage"
                              type="radio"
                              name="allowedExtraTimeType"
                              value={EXTRA_TIME_TYPES.percentage}
                            />
                          </div>
                        </Col>
                        <Col className="col-lg-3">
                          <Field
                            type="number"
                            step=".01"
                            name="allowedExtraTime"
                            as={InputSuffix}
                            value={values.allowedExtraTime}
                            suffix={
                              values.allowedExtraTimeType === EXTRA_TIME_TYPES.percentage
                                ? '%'
                                : 'mins'
                            }
                            className="text-center"
                          />
                        </Col>
                      </Row>
                    ) : null}

                    {/* Enable locking sections - NOT GOING TO USE IN CURRENT VERSION */}
                    {/* {values.questionStructure !== TEST_STRUCTURE.test ? (
                      <Row css={[styles.settings, spacer.padB30, spacer.padT40]}>
                        <Col className="col-lg-9">
                          <H5 workSans>Enable locking sections?</H5>
                          <P workSans small color="blackTransparent50">
                            Enable if a user need to completely finish one section before they can
                            proceed to the other
                          </P>
                        </Col>
                        <Col className="col-lg-3">
                          <label className="w-100">
                            <Field
                              css={styles.label}
                              type="checkbox"
                              name="enableLocking"
                              value={values.enableLocking}
                              checked={values.enableLocking}
                            />
                            <ToggleSwitch state={values.enableLocking} />
                          </label>
                        </Col>
                      </Row>
                    ) : null} */}
                  </Col>

                  {/* Level of locking - NOT GOING TO USE IN CURRENT VERSION */}
                  {/* <Col className="col-lg-10">
                    {values.questionStructure === TEST_STRUCTURE.subsections &&
                      values.enableLocking && (
                        <Fragment>
                          <Row
                            css={[styles.settings, spacer.padB30, spacer.padT40]}
                            className="last-child"
                          >
                            <Col className="col-lg-9">
                              <H5 workSans>
                                Select the level of locking you would like to activate
                              </H5>
                            </Col>
                          </Row>
                          <Row css={[styles.settings, spacer.padB30]} className="no-border">
                            <Col className="col-lg-4">
                              <VisualizerCard
                                variant={visualizerCard.SECTION_LOCK}
                                handleOnSelect={() => {}}
                              />
                            </Col>
                            <Col className="col-lg-4">
                              <VisualizerCard
                                variant={visualizerCard.SUBSECTION_LOCK}
                                handleOnSelect={() => {}}
                              />
                            </Col>
                            <Col className="col-lg-4">
                              <VisualizerCard
                                variant={visualizerCard.SINGLE_SUBSECTION_LOCK}
                                handleOnSelect={() => {}}
                              />
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                  </Col> */}

                  {/* Reports and solutions - NOT GOING TO USE IN CURRENT VERSION */}
                  {/* <Col className="col-lg-6">
                    <Row
                      css={[styles.settings, spacer.padB30, spacer.padT40]}
                      className="last-child border-top"
                    >
                      <Col className="col-lg-12">
                        <H5 workSans>At what level should reports and solutions be displayed?</H5>
                      </Col>
                    </Row>
                    <Row css={[styles.settings, spacer.padB30]} className="last-child">
                      <Col className="col-lg-4">
                        <label className="h-100">
                          <Field
                            disabled={isSubmitting}
                            css={styles.label}
                            type="radio"
                            name="resultsDisplayedAt"
                            value={TEST_STRUCTURE.test}
                          />
                          <DescriptiveCheckBox
                            selected={values.resultsDisplayedAt === TEST_STRUCTURE.test}
                            variant={descriptiveCheckbox.TEST_COMPLETION}
                          />
                        </label>
                      </Col>
                      <Col className="col-lg-4">
                        <label className="h-100">
                          <Field
                            disabled={isSubmitting}
                            css={styles.label}
                            type="radio"
                            name="resultsDisplayedAt"
                            value={TEST_STRUCTURE.sections}
                          />
                          <DescriptiveCheckBox
                            selected={values.resultsDisplayedAt === TEST_STRUCTURE.sections}
                            variant={descriptiveCheckbox.SECTION_COMPLETION}
                          />
                        </label>
                      </Col>
                      <Col className="col-lg-4">
                        <label className="h-100">
                          <Field
                            disabled={isSubmitting}
                            css={styles.label}
                            type="radio"
                            name="resultsDisplayedAt"
                            value={TEST_STRUCTURE.subsections}
                          />
                          <DescriptiveCheckBox
                            selected={values.resultsDisplayedAt === TEST_STRUCTURE.subsections}
                            variant={descriptiveCheckbox.SUBSECTION_COMPLETION}
                          />
                        </label>
                      </Col>
                      {errors.resultsDisplayedAt && touched.resultsDisplayedAt && (
                        <FieldError>{errors.resultsDisplayedAt}</FieldError>
                      )}
                    </Row>
                  </Col> */}
                </Row>
              </Container>

              {/* Save settings section */}
              <div className="save-settings" css={[styles.saveSettings, spacer.padBT20]}>
                <Container>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {!isSubmitting && (
                        <A href="#" onClick={() => navigate(-1)}>
                          Cancel
                        </A>
                      )}
                    </div>

                    <div className="d-flex">
                      {(!editTest || isInDraftMode) && (
                        <div>
                          <Button
                            loading={isSubmitting && submitDraft}
                            disabled={isSubmitting && !submitDraft}
                            type="button"
                            onClick={() => {
                              setSubmitDraft(true);
                              submitForm();
                            }}
                          >
                            Save as Draft
                          </Button>
                        </div>
                      )}
                      {editTest && (
                        <div css={spacer.mrL20}>
                          <Button
                            outline={isInDraftMode}
                            type="button"
                            loading={isSubmitting && !submitDraft}
                            disabled={isSubmitting && submitDraft}
                            onClick={() => {
                              setSubmitDraft(false);
                              submitForm();
                            }}
                          >
                            {editTest && !isInDraftMode ? 'Save and Continue' : 'Publish'}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default TestSettings;
