import { useState, useEffect, useCallback } from 'react';
import { useQuery, useMutation } from 'react-query';
import request from 'utils/request';
import { createContainer } from 'unstated-next';

import { api } from 'globals/constants';
import store from 'store';
import { isEqual } from 'lodash-es';

import LogRocket from 'logrocket';

const useUserInfo = () => {
  const initialState = () => store.get('tctUser') || {};
  const [userInfo, updateUserInfo] = useState(initialState);

  useEffect(() => {
    store.set('tctUser', userInfo);
  }, [userInfo]);

  const setUserInfo = useCallback(
    (updatedUserInfo) => {
      if (!isEqual(userInfo, updatedUserInfo)) {
        updateUserInfo(updatedUserInfo);
      }
    },
    [userInfo]
  );

  return { ...userInfo, setUserInfo };
};

export const UserInfoContainer = createContainer(useUserInfo);

const useLogin = () => {
  const { setUserInfo } = UserInfoContainer.useContainer();
  const mutation = useMutation(
    async ({ email, password, orgId }) => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.auth.login,
        payload: { email, password, orgId }
      });

      return response;
    },
    {
      onSuccess(res) {
        console.info(res);
        if (!res.error) {
          setUserInfo({
            token: res.data.access_token,
            ...res.data.user
          });

          // Set Logrocket info
          LogRocket.identify(res.data.user._id, {
            name: `${res.data.user.name}`,
            email: res.data.user.email
          });
        }
      },
      onError(err) {
        throw new Error(err);
      }
    }
  );

  const { mutateAsync, data, error, status } = mutation;

  return { login: mutateAsync, data, error, status };
};

export const useValidateToken = () => {
  const { data, error, status, refetch } = useQuery(
    'validateToken',
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.auth.authenticateToken
      });
      return response;
    },
    {
      manual: true
    }
  );
  return { validateToken: refetch, data, error, status };
};

export const useLogout = () => {
  const { setUserInfo } = UserInfoContainer.useContainer();
  const logout = useCallback(() => {
    setUserInfo({});
  }, [setUserInfo]);
  return logout;
};

export const useForgottenPassword = () => {
  const mutation = useMutation(async (body) => {
    const { response } = await request({
      method: 'POST',
      endpoint: api.endpoints.auth.requestResetPassword,
      payload: body
    });
    return response;
  });

  const { mutateAsync, data, error, status } = mutation;
  return { forgotPasswordMutation: mutateAsync, data, error, status };
};

// export const useResetPassword = () => {
//   const [resetPasswordMutation, { data, error, status }] = useMutation(
//     async ({ key, login, pass_1, pass_2 }) => {
//       const { response } = await request({
//         method: 'POST',
//         endpoint: api.endpoints.resetPasswordEmail,
//         payload: {
//           key,
//           login,
//           pass_1,
//           pass_2
//         }
//       });
//       return response;
//     },
//     {
//       onSuccess(res) {
//         console.info(res);
//       }
//     }
//   );
//   return { resetPasswordMutation, data, error, status };
// };

export const useGetOrganisations = () => {
  const { data, isSuccess } = useQuery('getOrgs', async () => {
    const { response } = await request({
      method: 'GET',
      endpoint: api.endpoints.org.getOrgsNames
    });
    return response;
  });

  return { organisationsList: data, isSuccess };
};

export default useLogin;
