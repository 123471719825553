/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import { Input, Select } from 'components/htmlElements/Fields';
import Checkbox from 'components/Checkbox';
import InputSuffix from 'components/InputSuffix';
import P from 'components/htmlElements/P';
import { QUESTION_USAGE_TYPE } from 'globals/constants';

import { spacer } from 'styles/utilities';
import * as styles from './QuestionSettingsBand.styles';

const QuestionSettingsBand = ({ values, hideDefaultMarks, hidePartialMarking }) => {
  const [setInstructions, toggleSetInstructions] = useState(
    values.instructionsText.length || false
  );
  useEffect(() => {
    toggleSetInstructions(values.instructionsText.length || false);
  }, [values.instructionsText.length]);

  return (
    <div css={spacer.mrBT20}>
      <div className="d-flex flex-wrap">
        <div css={styles.listItem}>
          <Field as={Select} name="usage" value={values.usage}>
            <option disabled defaultValue="" label="Question Usage" />
            {Object.keys(QUESTION_USAGE_TYPE).map((type) => (
              <option
                value={QUESTION_USAGE_TYPE[type]}
                label={QUESTION_USAGE_TYPE[type]}
                key={QUESTION_USAGE_TYPE[type]}
              />
            ))}
          </Field>
        </div>
        <div css={styles.listItem}>
          <Checkbox
            name="instructionsText"
            label="Instructions"
            checked={setInstructions}
            onChange={() => toggleSetInstructions(!setInstructions)}
          />
        </div>

        <div css={styles.listItem}>
          <Field as={Checkbox} name="pauseTimer" label="Pause Timer" type="checkbox" />
        </div>

        {!hideDefaultMarks && (
          <div css={styles.listItem}>
            <label htmlFor="defaultMarks" css={styles.miniInput}>
              <P small workSans medium>
                Default Marks:
              </P>
              <Field as={Input} type="number" name="defaultMarks" />
            </label>
          </div>
        )}

        <div css={styles.listItem}>
          <Field
            as={Checkbox}
            type="checkbox"
            name="negativeMarkingEnabled"
            label="Negative Marks enabled"
          />
        </div>
        {values.negativeMarkingEnabled && (
          <div css={styles.listItem}>
            <label htmlFor="defaultNegativeMarks" css={styles.miniInput}>
              <P small workSans medium>
                Negative Marks:
              </P>
              <Field as={Input} type="number" name="defaultNegativeMarks" />
            </label>
          </div>
        )}

        {!hidePartialMarking && (
          <div css={styles.listItem}>
            <Field
              as={Checkbox}
              type="checkbox"
              name="partialMarking"
              label="Marks per correct answer"
            />
          </div>
        )}

        <div css={styles.listItem}>
          <Field
            as={Checkbox}
            name="timerAtQuestion"
            label="Set time limit at question"
            type="checkbox"
          />
        </div>
        {values.timerAtQuestion && (
          <div css={styles.listItem}>
            <Field
              as={InputSuffix}
              type="number"
              step=".01"
              name="timeLimit"
              label="Set time limit at question"
              suffix="mins"
              min="0"
            />
          </div>
        )}
      </div>
      {setInstructions && (
        <Field
          css={spacer.mrBT15}
          as={Input}
          type="text"
          name="instructionsText"
          placeholder="Enter instructions here"
        />
      )}
    </div>
  );
};

QuestionSettingsBand.propTypes = {
  values: PropTypes.object.isRequired,
  hideDefaultMarks: PropTypes.bool,
  hidePartialMarking: PropTypes.bool
};
QuestionSettingsBand.defaultProps = {
  hideDefaultMarks: false,
  hidePartialMarking: false
};

export default QuestionSettingsBand;
