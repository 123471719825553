import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { map } from 'lodash-es';

const useCreateMedia = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (mediaFile) => {
      const formData = new FormData();
      formData.append('file', mediaFile, mediaFile.name);

      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.media.create,
        body: formData,
        headers: {
          Accept: '*/*',
          'Content-Length': mediaFile.size
        },
        customOptions: {
          removeContentType: true
        }
      });
      return response;
    },
    {
      onSettled() {
        queryClient.invalidateQueries('getMedias');
      }
    }
  );

  const { mutate, isError, isLoading, isSuccess } = mutation;

  return { addMedia: mutate, isError, isLoading, isSuccess };
};

const MEDIA_FETCHING_LIMIT = 100;
const useGetAllMedia = () => {
  const {
    data,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteQuery(
    'getMedias',
    async ({ pageParam = 0 }) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.media.getAll}?orderBy=-createdAt&limit=${MEDIA_FETCHING_LIMIT}&offset=${pageParam}`
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.data.length ? allPages.length * MEDIA_FETCHING_LIMIT : undefined
    }
  );

  const [mediaData, updateMediaData] = useState(data);
  useEffect(() => {
    // if (data && data.data !== 'undefined') {
    if (data && data !== 'undefined' && data.pages.length) {
      const updatedData = data;

      // Needed since infinite query returns data in pages. Check react-query docs for more info
      const combinedPagesData = data.pages.map((pageData) => pageData.data).flat();
      updatedData.data = combinedPagesData;

      updatedData.data = map(updatedData.data, (mediaObject) => {
        const updatedMediaObject = mediaObject;
        updatedMediaObject.url = updatedMediaObject.url.replace(
          process.env.S3_DOMAIN,
          process.env.IMGIX_DOMAIN
        );
        return updatedMediaObject;
      });
      updateMediaData(updatedData);
    }
  }, [data]);

  return {
    data: mediaData,
    isError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  };
};

const useGetMedia = (mediaId) => {
  const { data, isError, isLoading, isSuccess } = useQuery(
    'getMedia',
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.media.get(mediaId)
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  console.info(data, 'single media');

  return { data, isError, isLoading, isSuccess };
};

const useDeleteMedia = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (mediaId) => {
      const { response } = await request({
        method: 'DELETE',
        endpoint: api.endpoints.media.delete(mediaId)
      });
      return response;
    },
    {
      onSettled() {
        queryClient.invalidateQueries('getMedias');
      }
    }
  );

  const { mutate, isError, isLoading, isSuccess } = mutation;

  return { deleteMedia: mutate, isError, isLoading, isSuccess };
};

export { useCreateMedia, useGetAllMedia, useGetMedia, useDeleteMedia };
