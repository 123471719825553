/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/react';

import { colors, misc } from 'styles/utilities';

export const pill = (props) => css`
  padding: 6px 10px;
  margin: 0;
  color: ${colors[props.color] || colors.navyBlue};
  background-color: ${colors.lightGrey};
  border-radius: ${misc.largeBorderRadius};
`;
