// @ref for basic understanding of question type: https://authorguide.learnosity.com/hc/en-us/articles/360000448917-Cloze-Text
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

import ResponseEditing from './ResponseEditing';
import ResponseUI from './ResponseUI';

export default class ResponsePlugin extends Plugin {
  static get requires() {
    return [ResponseEditing, ResponseUI];
  }
}
