/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { pick, omit } from 'lodash-es';

import Modal from 'components/Modal';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import A from 'components/htmlElements/A';
import P from 'components/htmlElements/P';
import H2 from 'components/htmlElements/H2';
import Button from 'components/htmlElements/Button';
import { FieldWrapper, InlineFields } from 'components/htmlElements/Fields';
import InputSuffix from 'components/InputSuffix';
import { FieldRenderer } from 'components/FieldWithFormik';

import CreateTestContainer from 'hooks/useCreateTest';

import * as modalStyles from 'components/Modal/Modal.styles';
import { spacer } from 'styles/utilities';

import settingsHelpers from 'utils/settingsHelpers';
import { TEST_STRUCTURE, EXTRA_TIME_TYPES } from 'globals/constants';
import { useSectionSettingsContext } from './SectionSettingsContext';

const { formattedForAPI, getSectionSettingsFormFields, yupValidations } = settingsHelpers;

const Schema = Yup.object().shape({
  name: Yup.string().required(yupValidations.defaultRequired),
  defaultMarks: Yup.number().required(yupValidations.defaultMarks),
  defaultNegativeMarks: Yup.number().required(yupValidations.defaultNegativeMarks)
});

const SectionSettingsForm = () => {
  const {
    closeModal,
    sectionSettings,
    testSettings,
    isNewSection,
    hideDeleteOption
  } = useSectionSettingsContext();

  const { addNewSection_, updateSection_, deleteSection } = CreateTestContainer.useContainer();

  const [timerAvailable, toggleTimerAvailability] = useState(
    testSettings.questionStructure === TEST_STRUCTURE.sections && testSettings.settings.enableTimer
  );
  useEffect(() => {
    toggleTimerAvailability(
      testSettings.questionStructure === TEST_STRUCTURE.sections &&
        testSettings.settings.enableTimer
    );
  }, [testSettings.questionStructure, testSettings.settings.enableTimer]);

  // Check if extra time is enabed at test level
  const [extraTimerAvailable, toggleExtraTimerAvailability] = useState(
    testSettings.questionStructure === TEST_STRUCTURE.sections &&
      testSettings.settings.allowedExtraTime !== undefined
  );
  useEffect(() => {
    toggleExtraTimerAvailability(
      testSettings.questionStructure === TEST_STRUCTURE.sections &&
        testSettings.settings.allowedExtraTime !== undefined
    );
  }, [testSettings.questionStructure, testSettings.settings.allowedExtraTime]);

  // Dynamically updating fields based on test settings
  const [fields, updateFields] = useState({});
  useEffect(() => {
    updateFields(() => {
      if (!isNewSection && sectionSettings) {
        const updatedSectionSettings = extraTimerAvailable
          ? sectionSettings
          : omit(sectionSettings, ['settings.allowedExtraTime', 'settings.allowedExtraTimeType']);

        return getSectionSettingsFormFields({
          newSection: false,
          sectionSettings: updatedSectionSettings,
          testSettings,
          additionalFieldsToAdd: {
            ...(timerAvailable && { timeLimit: sectionSettings.settings.timeLimit || '' }),
            ...(extraTimerAvailable && {
              allowedExtraTime:
                sectionSettings.settings.allowedExtraTime || testSettings.settings.allowedExtraTime
            }),
            ...(extraTimerAvailable && {
              allowedExtraTimeType: testSettings.settings.allowedExtraTimeType
            })
          }
        });
      }
      return getSectionSettingsFormFields({
        newSection: true,
        sectionSettings,
        testSettings,
        additionalFieldsToAdd: {
          ...(timerAvailable && { timeLimit: '' }),
          ...(extraTimerAvailable && { allowedExtraTime: testSettings.settings.allowedExtraTime }),
          ...(extraTimerAvailable && {
            allowedExtraTimeType: testSettings.settings.allowedExtraTimeType
          })
        }
      });
    });
  }, [extraTimerAvailable, isNewSection, sectionSettings, testSettings, timerAvailable]);

  // Set initial Formik values
  const formikInitialValues_ = Object.keys(fields).length
    ? Object.keys(fields).map((fieldKey) => ({
        [fieldKey]: pick(fields[fieldKey], 'value').value
      }))
    : [{}];
  const formikInitialValues = Object.assign(...formikInitialValues_);

  // Form submission
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    if (isNewSection) {
      await addNewSection_(formattedForAPI(values));
    } else {
      await updateSection_(formattedForAPI(values));
    }
    actions.setSubmitting(false);
    closeModal();
  };

  // Handle Deletion of section
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);
  const handleDelete = async (event, sectionID) => {
    toggleDeleteConfirmation(true);
    await deleteSection(sectionID);
    toggleDeleteConfirmation(false);
    closeModal();
  };

  return (
    <Fragment>
      <div css={modalStyles.header}>
        <H2>{isNewSection ? 'Add' : 'Edit'} Section</H2>
      </div>
      <div css={[spacer.mrLR20, spacer.mrT20]}>
        <Formik
          initialValues={formikInitialValues}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={Schema}
        >
          {({ isSubmitting }) => {
            if (!Object.keys(fields).length) return null;

            return (
              <Form>
                {fields._id && (
                  <FieldWrapper>
                    <FieldRenderer field={fields._id} fieldName="_id" />
                  </FieldWrapper>
                )}

                {fields.name && (
                  <FieldWrapper>
                    <FieldRenderer field={fields.name} fieldName="name" />
                  </FieldWrapper>
                )}

                <FieldWrapper>
                  <FieldRenderer field={fields.defaultMarks} fieldName="defaultMarks" />
                  <FieldRenderer field={fields.markingEnabled} fieldName="markingEnabled" />
                </FieldWrapper>

                <InlineFields>
                  <FieldRenderer
                    field={fields.defaultNegativeMarks}
                    fieldName="defaultNegativeMarks"
                  />
                  <FieldRenderer
                    field={fields.negativeMarkingEnabled}
                    fieldName="negativeMarkingEnabled"
                  />
                </InlineFields>

                {timerAvailable && (
                  <FieldWrapper>
                    <FieldRenderer
                      field={fields.timeLimit}
                      fieldName="timeLimit"
                      as={InputSuffix}
                      step=".01"
                      suffix={fields.timeLimit.meta.suffix}
                    />
                  </FieldWrapper>
                )}

                {extraTimerAvailable ? (
                  <FieldWrapper>
                    <FieldRenderer
                      field={fields.allowedExtraTime}
                      fieldName="allowedExtraTime"
                      as={InputSuffix}
                      step=".01"
                      suffix={
                        fields.allowedExtraTimeType.value === EXTRA_TIME_TYPES.percentage
                          ? '%'
                          : 'mins'
                      }
                    />
                  </FieldWrapper>
                ) : (
                  ''
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    {!isNewSection && !showDeleteConfirmation && !hideDeleteOption && (
                      <A
                        as="span"
                        onClick={() => toggleDeleteConfirmation(true)}
                        css={spacer.mrR20}
                        color="red"
                      >
                        Delete
                      </A>
                    )}
                  </div>

                  <div className="d-flex justify-content-end align-items-center">
                    <A as="span" onClick={closeModal} css={spacer.mrR20}>
                      Cancel
                    </A>
                    <Button type="submit" loading={isSubmitting}>
                      {isNewSection ? 'Add' : 'Edit'} Section
                    </Button>
                  </div>
                </div>

                {showDeleteConfirmation ? (
                  <div css={spacer.mrT20}>
                    <P>Are you sure? This action is irreversible</P>
                    <div className="d-flex">
                      <A
                        as="span"
                        onClick={(e) => handleDelete(e, fields._id.value)}
                        css={spacer.mrR10}
                      >
                        Yes
                      </A>
                      <A as="span" onClick={() => toggleDeleteConfirmation(false)}>
                        No
                      </A>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </Fragment>
  );
};

const SectionSettingsModal = (props) => {
  const { isOpen, closeModal } = useSectionSettingsContext();

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <SectionSettingsForm {...props} />
    </Modal>
  );
};

// SectionSettingsForm.whyDidYouRender = true;

export default SectionSettingsModal;
