import React from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';

import { useGetCourse } from 'api/course';
import { useGetLessons } from 'api/courseLessons';

import CourseContainer from 'hooks/useCreateCourse';
import LessonsContainer from 'hooks/useCourseLessons';

import { ROUTE_PATHS, COURSE_STRUCTURE } from 'globals/constants';
import CourseOverview from './page';

const CourseOverviewContainer = ({ courseId }) => {
  const { data: courseData, status, error } = useGetCourse(courseId);
  const { data: lessons, error: lessonsError, status: lessonsStatus } = useGetLessons(
    courseId,
    COURSE_STRUCTURE.course
  );
  if (error || (courseData && courseData.error) || lessonsError || (lessons && lessons.error)) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  if (status !== 'success' || (status !== 'idle' && lessonsStatus !== 'success')) {
    return <PageLoader isFetching isRelative atPageHeight />;
  }

  if (courseData) {
    return (
      <CourseContainer.Provider initialState={courseData.data}>
        <LessonsContainer.Provider initialState={lessons && lessons.data}>
          <CourseOverview />
        </LessonsContainer.Provider>
      </CourseContainer.Provider>
    );
  }

  return null;
};

CourseOverviewContainer.propTypes = {
  courseId: PropTypes.string.isRequired
};

export default CourseOverviewContainer;
